import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import toastr from 'toastr';
import objectToFormdata from 'object-to-formdata';
import Loadable from 'react-loading-overlay';
import urlParse from 'url-parse';
import reactWindowSize from 'react-window-size';
import 'video-react/dist/video-react.css';
import apiClient from '../util/api-client';
import AnimationGetUrlModal from './AnimationGetUrlModal';
import ProjectInfoSection from './ProjectInfoSection';
import AnimationUploaderModal from './AnimationUploaderModal';
import PublicUrlModal from './PublicUrlModal';
import { reload as reloadActivities } from './ActivitiesButton';
import GetUrlYoutube from './GetUrlYoutube'
import IconUploadYoutube from 'images/upload_youtube.png';
import IconUploadCapture from 'images/icon-www.png';
import IconUploadImage from 'images/upload_image.png';
import IconUploadVideo from 'images/upload_video.png';

const url = new URL(location.href);

const ONE_GB_TO_BYTES = Math.pow(2,30);
const PLANS = ['free', 'minimum', 'basic', 'master']
const PLANS_TEXT = ['無料','ミニマム','ベーシック','マスター']
const REGEXP_URL = /projects\/(\d+)\/(\d+)\/akaire\/(\S)/
const TICKET_REGEXP_URL = /projects\/(\d+)\/(\d+)\/(\d+)\/akaire\/(\S)/

class CreateNewAkaire extends Component {
  constructor(props) {
    super(props);
    const { id, baseURL, last_page_id, is_create_video_embed, is_create_pass_video, current_total_size } = props;
    this.props = { ...this.props, ...props };
    this.state = {
      user: {},
      project: {},
      loadingText: null,
      percentComplete: 0,
      animations: [],
      markers: [],
      pages: [],
      currentAnimationId: null,
      currentPageId: last_page_id,
      assignments: [],
      shouldShowGetUrlModal: false,
      shouldShowProjectShareModal: false,
      shouldShowProjectFormModal: false,
      shouldShowAnimationUploaderModal: false,
      shouldShowAnimationUploaderImageModal: false,
      shouldShowCompletionModal: false,
      shouldShowPublicUrlModal: false,
      shouldShowGetUrlYoutube: false,
      isLoading: false,
      activeTab: 'markers',
      timerCheckAnimation: null,
      responseConvertVideo: null,
      timerDownloadExtensionFile: null,
      downloadExtensionUrl: null,
      convertFile: false,
      completed: 0,
      finishConvert: false,
      changeCss: false,
      uploadedDataInfo: {},
      isNewAnimationCreated: false,
      membersInComment: [],
      animationSelectCurrent: null,
      is_create_video_embed: is_create_video_embed,
      is_capture_video_youtube: false,
      is_cancel_capture_video_youtube: false,
      isShowFullscreenAnimation: false,
      is_create_pass_video: is_create_pass_video,
      isShowInputPassVideo: false,
      passwordShareVideo: '',
      isDisabledButtonSavePass: true,
      isShowInputDemo: false,
      currentPasswordShareVideo: '',
      currentTotalSize: current_total_size,
    };
    this.currentURL = baseURL;
    if(baseURL && baseURL.match(REGEXP_URL)) {
      this.baseURL = '/akaire_feature/projects/' + baseURL.match(REGEXP_URL)[1] + '/tasks/' + baseURL.match(REGEXP_URL)[2];
    } else if(baseURL && baseURL.match(TICKET_REGEXP_URL)){
      this.baseURL = '/akaire_feature/projects/' + baseURL.match(TICKET_REGEXP_URL)[1] + '/tasks/' + baseURL.match(TICKET_REGEXP_URL)[2] + '/tickets/' + baseURL.match(TICKET_REGEXP_URL)[3];
    } else {
      this.baseURL = baseURL || `/akaire_feature/projects/${id}`;
    }
    this.apiClient = apiClient({ baseURL: this.baseURL });
    this.percentConvert = null;
  }

  componentDidUpdate(_, prev) {
    const { is_cancel_capture_video_youtube, is_capture_video_youtube } = this.state;
    if (is_cancel_capture_video_youtube && is_capture_video_youtube !== prev.is_capture_video_youtube &&
      is_capture_video_youtube === false && prev.is_capture_video_youtube === true) {
      this.setState({is_cancel_capture_video_youtube: false})
    }
  }

  toggleElementList(className, show = true) {
    const elementList = document.getElementsByClassName(className);
    for (let i = 0; i < elementList.length; i ++) {
      elementList[i].style.visibility = show ? "visible" : "hidden";
    }
  }

  currentAnimation = () => this.state.animations.find(_ => _.id === this.state.currentAnimationId)
  fromProjectId = () => {
    return url.searchParams.get('from_project_id');
  }
  fromTaskId = () => {
    return url.searchParams.get('from_task_id');
  }
  fromWorkId = () => {
    return url.searchParams.get('from_work_id');
  }
  fromPageId = () => {
    return url.searchParams.get('from_page_id');
  }
  textFor = (plan) => {
    return PLANS.indexOf(plan) >= 0 && PLANS_TEXT[PLANS.indexOf(plan)]
  }
  showAlertLimitVideo = () => {
    const {current_limit_video: currentLimitVideo, current_subscription_type: currentSubscriptionType} = this.props;
    const nextLevelPlan = this.textFor(PLANS[PLANS.indexOf(currentSubscriptionType)+1])
    const text = nextLevelPlan ? `${nextLevelPlan}プラン以上にアップグレードするか、データの圧縮をしてからアップロードをお願い致します。` : ''
    toastr.error(`${this.textFor(currentSubscriptionType)}プランの場合、1回でのアップロード制限は${currentLimitVideo}GB以内です。${text}`);
  }

  convertVideo = ({ signedUrl }, filename) => {
    const checkUploadNewPage = $('#uploadNewPagePopup').attr('data-upload-new') == 'true'
    const pageId = checkUploadNewPage ? 'new' : $('#uploadVersionPopup').attr('data-page-id')
    var id_interval = setInterval(() => {
      this.apiClient.put(
        `${pageId ? 'animations/convert_mp4' : 'convert_mp4'}`,
        {
          pathname: decodeURI(urlParse(signedUrl).pathname),
          page_id: pageId,
          filename: filename,
          from_project_id: this.fromProjectId() ,
          from_task_id: this.fromTaskId(),
          from_work_id: this.fromWorkId(),
          from_page_id: this.fromPageId(),
        }) // decode the file name to the correct original name
        .then((res) => {
          if (res.data.type == 'success') {
            setTimeout(() => {
              toastr.success('動画をアップロードしました。');
              reloadActivities();
              this.setState({ convertFile: false });
              this.finishConvert(true)
            }, 1000);
            setTimeout(() => {
              this.close('AnimationUploaderModal');
              this.endLoading();
            }, 2000);
            clearInterval(id_interval);
            this.reloadAfterUpload(res.data.page_id, res.data.animation_id, pageId == 'new')
          }
        })
        .catch(() => {
          this.endLoading();
          this.close('AnimationUploaderModal');
        })
    }, 2000);
  }
  convertPdf = (file) => {
    let formData = new FormData();
    const checkUploadNewPage = $('#uploadNewPagePopup').attr('data-upload-new') == 'true'
    const pageId = checkUploadNewPage ? 'new' : $('#uploadVersionPopup').attr('data-page-id')
    formData.append('file', file);
    this.startLoading(`PDF file is uploading...`)
    this.apiClient.post(`${pageId ? 'animations/convert_pdf' : 'convert_pdf'}`, formData)
      .then((res) => {
        if (res.data.success) {
          this.startLoading(`PDF file is converting...`)
          var id_interval = setInterval(() => {
            this.apiClient.get(`${ pageId ? 'animations/check_status_convert_pdf' : 'check_status_convert_pdf' }?extension_id=${res.data.extension_id}&page_id=${pageId}`)
              .then((res) => {
                if (res.data.type == 'success') {
                  setTimeout(() => {
                    toastr.success('動画をアップロードしました。');
                    reloadActivities();
                    this.finishConvert(true)
                  }, 1000);
                  setTimeout(() => {
                    this.close('AnimationUploaderModal');
                    this.endLoading();
                    this.reloadAfterUpload(res.data.page_id, res.data.animation_id, pageId == 'new')
                  }, 2000);
                  clearInterval(id_interval);
                }
              })
              .catch(() => {
                Popup.alert(`PDFファイルを画像に変換処理が失敗しました。再度やり直してください。`)
                clearInterval(id_interval);
                this.endLoading();
                this.close('AnimationUploaderModal');
              })
          }, 5000);
        }
      })
      .catch(() => {
        Popup.alert(`PDFファイルを画像に変換処理が失敗しました。再度やり直してください。`)
        toastr.error('画像のアップロードに失敗しました。');
        this.endLoading();
        this.close('AnimationUploaderModal');
      })
  }
  file_extension = (filename) => {
    const extensions = filename.split('.').slice(-1)
    if (extensions.length) { return extensions[0].toUpperCase() }
  }

  onSubmitProjectForm = (name, description) => {
    const data = objectToFormdata({
      project: {
        name,
        description
      }
    });
    return apiClient().patch(`${this.baseURL}.json`, data)
      .then(() => {
        toastr.success('プロジェクトを更新しました');
        // reloadActivities();
      })
      .catch(() => {
        toastr.error('プロジェクトを更新できませんでした');
      })
      .finally(() => {
        this.close('ProjectFormModal');
      });
  }

  onClickInputFile = () => {
    this.fetchUploadedInfo();
  }

  fetchUploadedInfo = () => {
    return this.apiClient.get(`/uploaded_data_info`)
      .then(_ => this.setState({ uploadedDataInfo: _.data }))
      .catch(console.error);
  }

  finalizeAnimation = () => {
    const { currentAnimationId } = this.state;
    this.startLoading('Now processing...');
    return this.apiClient.post(`animations/${currentAnimationId}/finalization`)
      .then(() => {
        toastr.success('完成しました');
        // reloadActivities();
      })
      .catch(() => {
        toastr.error('完成できませんでした');
      })
      .finally(this.endLoading);
  }

  toggle = bool => name => {
    this.setState({ [`shouldShow${name}`]: bool });
    if(!bool) {
      if($('#uploadNewPagePopup').attr('data-upload-new') == 'true') {
        $('#uploadNewPagePopup').modal('show')
      } else {
        $('#uploadVersionPopup').modal('show')
      }
    }
  }
  open = this.toggle(true)
  close = this.toggle(false)

  changeURL = (item, newPage = false, idAkaire = null) => {
    let baseURL = this.props.baseURL;
    if(baseURL && baseURL.match(REGEXP_URL)) {
      let pageId = 'new';
      let versionId = 'new';
      let akareId = 'new';
      this.state.pages.map((e,i) => {
        if (item == e.id) {
          pageId = e.id;

          if (this.state.animations.length > 0 && !newPage) {
            this.state.animations.map((item, index) => {
              if (item.id === this.state.currentAnimationId) {
                versionId = item.id;
              }
            });
          }
        }
      });

      if (idAkaire) {
        akareId = idAkaire;
      }
      if (akareId == 'new' && pageId == 'new' && versionId == 'new') {
        let url = '/projects/' + baseURL.match(REGEXP_URL)[1] + '/' + baseURL.match(REGEXP_URL)[2] + '/akaire/new';
      }
      else {
        let url = '/projects/' + baseURL.match(REGEXP_URL)[1] + '/' + baseURL.match(REGEXP_URL)[2] + '/akaire/'+ akareId + '/' + pageId + '/' + versionId;
      }
      window.history.pushState( "", "", url );
    }
  }
  checkExistsExtension = (extension) => {
    const { isLoading, currentAnimationId} = this.state;
    const message_error = '動画のダウンロードに失敗しました。';
    return this.apiClient.post(`animations/${currentAnimationId}/extension_files/check_exists`, { extension: extension })
      .then(_ => {
        const { data, status } = _;
        if (data.status == 'prepare' || data.status == 'started') {
          if (!isLoading) { this.startLoading(data.message) }
          if (data.status == 'prepare') { this.create_extension(extension) };
          if (data.status == 'started') { this.checkProgressPercent(extension) };
        } else {
          if (data.status == 'finished' || data.status == 'error') {
            clearInterval(this.timerDownloadExtensionFile);
            this.setState({ timerDownloadExtensionFile: null })
            this.setState({ percentComplete: 0 });
          }
          if (data.status == 'finished') {
            toastr.success('動画をダウンロードしました。');
            window.location.href = data.url;
          }
          if (data.status == 'error') { toastr.error(message_error); }
          this.endLoading();
        }
      })
      .catch((e) => {
        toastr.error(message_error);
        this.endLoading();
      })
      .finally(() => {
        // this.endLoading();
      });
  }
  checkProgressPercent = (extension) => {
    const currentAnimation = this.currentAnimation();
    const timeConvert = currentAnimation && currentAnimation.file_size ? ((((currentAnimation.file_size / 1024) * 10000) / (40 * 1024)) + 10000) / 100 : 1000;
    const timeForTimerDownload = timeConvert < 5000 ? 5000 : timeConvert + 1000;
    clearInterval(this.timerDownloadExtensionFile);
    this.timerDownloadExtensionFile = setInterval(() => {
      clearInterval(this.percentConvert);
      this.percentConvert = null;
      this.percentConvert = setInterval(() => {
        const { percentComplete, loadingText } = this.state;
        if (percentComplete < 99 && loadingText && loadingText.includes('converting')) {
          let text = loadingText.replace(/[\d%]/g,'');
          text += '\n' + percentComplete + '%';
          this.setState({ loadingText: text });
          this.setState({ percentComplete: percentComplete + 1 });
        }
      }, timeConvert);
      this.checkExistsExtension(extension)
    }, timeForTimerDownload);
  }
  create_extension = (extension) => {
    const { currentAnimationId } = this.state;
    this.apiClient.post(`animations/${currentAnimationId}/extension_files`, { extension: extension })
      .then(res => {
        this.checkProgressPercent(extension);
      })
      .catch((e) => {
        this.endLoading();
      })
  }

  onFinishUpload = ({ signedUrl }, filename) => {
    const checkUploadNewPage = $('#uploadNewPagePopup').attr('data-upload-new') == 'true'
    const pageId = checkUploadNewPage ? 'new' : $('#uploadVersionPopup').attr('data-page-id')
    this.apiClient.post(
      `${pageId ? 'animations' : ''}`,
      {
        pathname: decodeURI(urlParse(signedUrl).pathname),
        page_id: pageId,
        filename: filename,
        from_project_id: this.fromProjectId(),
        from_task_id: this.fromTaskId(),
        from_work_id: this.fromWorkId(),
        from_page_id: this.fromPageId(),
      })
      .then(async(response) => {
        if (!this.state.convertFile) {
          this.endLoading();
          toastr.success('動画をアップロードしました。');
          this.changeURL(this.state.currentPageId);
          this.close('AnimationUploaderModal');
          this.reloadAfterUpload(response.data.page_id, response.data.animation_id, pageId == 'new')
        }
      })
      .catch(() => {
        toastr.error('動画のアップロードに失敗しました。');
      })
  }
  onFinishUploadImage = ({ signedUrl }, filename) => {
    const checkUploadNewPage = $('#uploadNewPagePopup').attr('data-upload-new') == 'true'
    const pageId = checkUploadNewPage ? 'new' : $('#uploadVersionPopup').attr('data-page-id')
    this.endLoading();
    return this.apiClient.post(
      `${pageId ? 'animations' : ''}`,
      {
        pathname: urlParse(signedUrl).pathname,
        page_id: pageId, filename: filename,
        from_project_id: this.fromProjectId(),
        from_task_id: this.fromTaskId(),
        from_work_id: this.fromWorkId(),
        from_page_id: this.fromPageId(),
      })
      .then((response) => {
        toastr.success('画像をアップロードしました。');
        this.reloadAfterUpload(response.data.page_id, response.data.animation_id, pageId == 'new')
      })
      .catch(() => {
        toastr.error('画像のアップロードに失敗しました。');
      })
  }
  onUploadImage = (isSuccess, pageId) => {
    this.handleReset();
    this.endLoading();
    reloadActivities();
    if (isSuccess) {
      setTimeout(() => {
        this.reloadAfterUpload(pageId, null, pageId == 'new')
      }, 2000)
    }
  }
  startLoading = loadingText => {
    this.fadeInLoader();
    this.setState({ loadingText, isLoading: true });
  }
  endLoading = () => {
    this.setState({ loadingText: null, isLoading: false });
    $('.preloader').fadeOut();
    clearInterval(this.percentConvert);
    this.percentConvert = null;
  }
  convertFile = (file) => this.setState({ convertFile: file })
  finishConvert = (type) => {
    this.setState({ finishConvert: type })
  }
  startUploadVideo = (file) => {
    const extension = this.file_extension(file.name);
    const {current_limit_video: currentLimitVideo} = this.props;
    const { user: {total_file_size: totalFileSize, total_file_size_on_this_cycle: totalAllCycle, max_storage: maxStorage, total_file_size_limit: totalFileSizeLimit } } = this.state;
    var file_size = parseInt(file.size);
    if (file_size / ONE_GB_TO_BYTES > currentLimitVideo) {
      this.showAlertLimitVideo();
      return
    }
    if (file_size + Number(totalAllCycle) > Number(totalFileSizeLimit) || file_size + Number(totalFileSize) > Number(maxStorage)) {
      toastr.error('上限オーバーです　エラー');
      return;
    }
    this.startLoading(`${extension} file is uploading...`)
  }
  onSubmitCompletionModal = () => {
    this.finalizeAnimation().then(this.close.bind(this, 'CompletionModal'));
  }

  handleReset = () => {
    if (this.animationPlayer) {
      this.animationPlayer.reset()
    } else if(this.animationImage) {
      this.animationImage.reset()
    } else {
      return
    }
  }

  handleCapture = () => {
    if (this.animationPlayer) {
      this.animationPlayer.capture()
    } else if(this.animationImage){
      this.animationImage.capture()
    } else {
      return
    }
  }

  reloadAfterUpload = (pageId, animation_id, newPage) => {
    if(pageId) {
      window.location = window.location.href.split('?')[0].replace('#!', '') + `?update_version_page=${pageId}&animation_id=${animation_id}&new_page=${newPage}`;
    } else {
      window.location.href = Routes.list_temp_file_akaire_feature_akaires_path({upload_status: 'success'})
    }
  }

  fadeInLoader = () => {
    const pageId = $('#uploadVersionPopup').attr('data-page-id')
    if(pageId) {
      $('.preloader').fadeIn();
      setInterval(() => {
        $('.preloader').css('opacity', 0.2)
      }, 200);
    }
  }

  changeIsShowInputVideo = () => {
    const {isShowInputPassVideo, passwordShareVideo} = this.state
    if (isShowInputPassVideo) {
      if (!passwordShareVideo || passwordShareVideo === '') {
        this.setPasswordForShareVideo('', false);
        this.setState({
          isShowInputPassVideo: !isShowInputPassVideo,
          passwordShareVideo: ''
        })
        return
      }
      Popup.confirm(
        `パスワード設定をOFFにすると<br>設定したパスワードはリセットされます。<br>OFFにしてよろしいですか？`,
        {
          success: () => {
            this.setPasswordForShareVideo('', false);
            this.setState({
              isShowInputPassVideo: !isShowInputPassVideo,
              passwordShareVideo: ''
            })
          },
          cancel: () => {},
          confirmText: 'OK',
          width: 'auto',
        }
      )
      return
    }
    this.setState({
      isShowInputPassVideo: !isShowInputPassVideo,
      passwordShareVideo: ''
    })
  }

  setPasswordForShareVideo = (password, isshowPopup = true) => {
    const {currentAnimationId} = this.state
    this.apiClient.post(
      `animations/change_password_share_video`,
      { password: password, id: currentAnimationId }
    )
      .then(res => {
        if (isshowPopup) {
          Popup.alert('パスワードを保存しました。')
        }
      })
      .catch((e) => {
        Popup.alert(`An error occurred`)
        this.endLoading();
      })
  }

  checkPassWord = (event) => {
    const pass = $(event.target).val()
    this.setState({ passwordShareVideo: pass })
    if (pass.length >= 6) {
      this.setState({isDisabledButtonSavePass: false})
    } else {
      this.setState({isDisabledButtonSavePass: true})
    }
  }

  changeFocus = () => {
    const {isShowInputDemo} = this.state
    this.setState({isShowInputDemo: !isShowInputDemo});
  }

  closeModalShareVideo = () => {
    const {currentPasswordShareVideo, passwordShareVideo} = this.state
    if (currentPasswordShareVideo !== passwordShareVideo) {
      Popup.confirm(`パスワードが保存されていません。<br>閉じてよろしいですか？`, {
        success: () => {
          this.setState({
            [`shouldShowPublicUrlModal`]: false, passwordShareVideo: currentPasswordShareVideo,
            isShowInputDemo: true,
          })
        },
        confirmText: 'OK',
      })
      return
    }
    this.setState({ [`shouldShowPublicUrlModal`]: false })
  }

  renderNormal() {
    const { current_user_id: currentUserId, role, id, root_url: rootUrl, statuses,
      is_free_plan: isFreePlan, current_limit_video: currentLimitVideo, token_public_url: token_public_url,
      category, is_akapon_member: isAkaponMember, is_project_owner_member, isAkaponProject
    } = this.props;
    const {
      loadingText, isLoading, markers, assignments, shouldShowGetUrlModal, shouldShowAnimationUploaderModal, shouldShowAnimationUploaderImageModal,
      shouldShowPublicUrlModal, completed, shouldShowCompletionModal, user, project, uploadedDataInfo, shouldShowGetUrlYoutube,
      is_create_video_embed, is_create_pass_video, isShowInputPassVideo, passwordShareVideo, isDisabledButtonSavePass,
      isShowInputDemo
    } = this.state;
    const currentAnimation = this.currentAnimation();
    const token = token_public_url;
    const public_video_token = currentAnimation && currentAnimation.url_token;
    var darkGray = 'l-container u-bg--darkGray py-3 mt-2 mt-sm-0';
    if (!currentAnimation) {
      darkGray = darkGray.concat(" darkGray-animation")
    }

    return (
      <div className="animation-inspector">
        <Loadable
          className="loading-overlay"
          active={isLoading}
          text={loadingText || 'Now loading...'}
          zIndex={10000}
        >
          <div className={darkGray}>
            <div className="l-main">
              <div className="pane-wrapper">
                {/* アニメーション */}
                <div className='create-akaire-box text-center'>
                  <p className='create-akaire-box-title'>データをUpload</p>
                  <p className='create-akaire-box-title-content'>下記よりアップロードの種類を選択してください。</p>
                  <div className='text-left create-akaire-box-content'>
                    <NavLink href="#!" className='create-akaire-box-link' id="uploadVideo" onClick={this.open.bind(this, 'AnimationUploaderModal')}>
                      <img className="create-akaire-box-icon-upload filter-black-icon"
                        src={IconUploadVideo} alt="アップロード" width="45" />動画データを赤入れする
                    </NavLink>
                    <NavLink href="#!" className='create-akaire-box-link' id="uploadYoutube" onClick={this.open.bind(this, 'GetUrlYoutube')}>
                      <img className="create-akaire-box-icon-upload"
                        src={IconUploadYoutube} alt="アップロード" width="45" />YouTube動画を赤入れする
                    </NavLink>
                    <NavLink href="#!" className='create-akaire-box-link' id="uploadCaptureUrl" onClick={this.open.bind(this, 'GetUrlModal')}>
                      <img className="create-akaire-box-icon-upload"
                        src={IconUploadCapture} alt="アップロード" width="45" />WEBサイトのURLを赤入れする
                    </NavLink>
                    <NavLink href="#!" className='create-akaire-box-link' id="uploadImage" onClick={this.open.bind(this, 'AnimationUploaderImageModal')}>
                      <img className="create-akaire-box-icon-upload"
                        src={IconUploadImage} alt="アップロード" width="45" />画像またはPDFを赤入れする
                    </NavLink>
                  </div>
                </div>
              </div>
              {/* / pane-wrapper */}
            </div>
            {/* / l-main */}
          </div>
          {/* / l-container */}


          <AnimationGetUrlModal isOpen={shouldShowGetUrlModal} onClickClose={this.close.bind(this, 'GetUrlModal')} token={token}
            rootUrl={rootUrl} onFinish={this.onUploadImage} projectId={id} onStart={this.startLoading.bind(this, 'Now uploading...')}
            baseURL={this.baseURL} taskID={id} isAkaponProject={isAkaponProject} isCreateOfTemp={true}/>
          <GetUrlYoutube isOpen={shouldShowGetUrlYoutube}
            onClickClose={this.close.bind(this, 'GetUrlYoutube')} token={token}
            rootUrl={rootUrl} onFinish={this.onUploadImage} projectId={id}
            onStart={this.startLoading.bind(this, 'Now uploading...')}
            baseURL={this.baseURL} endLoading={this.endLoading}
            is_create_video_embed={is_create_video_embed}
          />
          <AnimationUploaderModal convertVideo={this.convertVideo} isOpen={shouldShowAnimationUploaderModal} onClickClose={this.close.bind(this, 'AnimationUploaderModal')}
            onStart={this.startUploadVideo} onFinish={this.onFinishUpload} projectId={id} baseURL={this.baseURL} completed={completed}
            convertFile={this.convertFile} statusConvert={this.finishConvert} finishConvert={this.state.finishConvert} endLoading={this.endLoading}
            type={'video'} convertPdf={this.convertPdf} user={user}
            oneGb={ONE_GB_TO_BYTES} currentLimitVideo={currentLimitVideo} uploadedDataInfo={uploadedDataInfo}
            onClickInputFile={this.onClickInputFile} is_mobile={false} currentTotalSize={this.state.currentTotalSize}/>
          <AnimationUploaderModal convertVideo={this.convertVideo} isOpen={shouldShowAnimationUploaderImageModal} onClickClose={this.close.bind(this, 'AnimationUploaderImageModal')}
            onStart={this.startUploadVideo} onFinish={this.onFinishUploadImage} projectId={id} baseURL={this.baseURL} completed={completed}
            convertFile={this.convertFile} statusConvert={this.finishConvert} finishConvert={this.state.finishConvert} endLoading={this.endLoading}
            type={'image'} convertPdf={this.convertPdf} user={user} oneGb={ONE_GB_TO_BYTES} currentLimitVideo={currentLimitVideo}
            uploadedDataInfo={uploadedDataInfo} onClickInputFile={this.onClickInputFile} is_mobile={false} currentTotalSize={this.state.currentTotalSize} />
          <PublicUrlModal isOpen={shouldShowPublicUrlModal} onClickClose={this.closeModalShareVideo} ad={isFreePlan} currentAnimation={currentAnimation} type={this.props.project_type} token={token} public_video_token={public_video_token} is_create_pass_video={is_create_pass_video} isShowInputPassVideo={isShowInputPassVideo}
            changeIsShowInputVideo={this.changeIsShowInputVideo} setPasswordForShareVideo={this.setPasswordForShareVideo}
            passwordShareVideo={passwordShareVideo} checkPassWord={this.checkPassWord}
            isDisabledButtonSavePass={isDisabledButtonSavePass} isAkaponMember={isAkaponMember}
            is_project_owner_member={is_project_owner_member}
            isShowInputDemo={isShowInputDemo} changeFocus={this.changeFocus} />
        </Loadable>
      </div>
    )
  }

  render() {
    return this.renderNormal();
  }
}

export default reactWindowSize(CreateNewAkaire);

export { CreateNewAkaire };
