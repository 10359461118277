import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactS3Uploader from 'react-s3-uploader';
import { Progress } from 'reactstrap';
import IconUploadVideo from 'images/upload_video.png';
import IconUploadImage from 'images/upload_image.png';
import apiClient from '../util/api-client';

const _3_GB = 3 * 1024 * 1024 * 1024;
const _10_GB = 10 * 1024 * 1024 * 1024;
const _10_MB = 10 * 1024 * 1024;
const MIME_ALLOWED_UPLOAD_WITH_VIDEO_TYPE = ['video/mp4', 'video/avi', 'video/mov', 'video/x-ms-wmv', 'video/x-msvideo', 'video/quicktime'];
const MIME_ALLOWED_UPLOAD_WITH_WEB_TYPE = ['image/png', 'image/jpeg', 'image/gif', 'application/pdf'];

export default class AnimationPublicUrlModal extends Component {
  constructor(props) {
    super(props);
    const { baseURL } = props;
    this.state = {
      completed: 0,
      time: 0,
      convert_file: false,
      finishConvert: false,
      showProgressBar: false
    };
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.modalRef = React.createRef();
    this.apiClient = apiClient({ baseURL: baseURL || "/" });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
      this.props.onClickClose();
    }
  };

  onUploadProgress(percent) {
    this.setState({showProgressBar: true})
    if(!this.state.convert_file){
      this.setState({ completed: percent })
    }
  }
  componentWillReceiveProps(nextProps) {
    const { statusConvert } = this.props
    if (nextProps.finishConvert !== this.state.finishConvert) {
      this.setState({ completed: 100 })
      statusConvert(false)
    }
    if (nextProps.completed !== this.state.completed) {
      setTimeout(() => {
        this.setState({ completed: 0, showProgressBar: false });
      }, 3000);
    }
    if (nextProps.isOpen !== this.props.isOpen) {
      if (!nextProps.isOpen && this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.setState({ completed: 0, showProgressBar: false });
      }
    }
  }
  calculateTime = (fileSize) => {
    var intervalTime = fileSize > _10_MB ? fileSize / 18000 : fileSize / 1800
    intervalTime = intervalTime > 3000 ? 3000 : intervalTime;
    this.intervalId = setInterval(() => {
      const { completed } = this.state;
      if (completed < 99) {
        this.setState({ completed: completed + 1 });
      }
    }, intervalTime);
  }

  onFinish = (signedUrlResult, uploadResult) => {
    const { onFinish, convertVideo } = this.props;
    const { convert_file } = this.state
    const filename = this.fileInput.value.replace(/^.*[\\/]/, '')
    onFinish(signedUrlResult, filename);
    if(convert_file){
      convertVideo(signedUrlResult, filename)
      this.setState({ convert_file: false })
    }
    this.fileInput.value = null;
    this.setState({ completed: 0 })
  }

  getNumberOfPagesPdf = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onloadend = () => {
        resolve(reader.result.match(/\/Type[\s]*\/Page[^s]/g)?.length || 1);
      }
      reader.readAsBinaryString(file);
    });
  }

  clearInputFileAndShowPopup(popupMessage, popupType = '') {
    $('.animation-uploader-modal input').val('');
    if (popupType === '') {
      Popup.alert(popupMessage, '', '', {kind: 'akaire'});
    } else {
      Popup.alert(popupMessage, popupType, '', {kind: 'akaire'});
    }
  }

  preprocess = async (file, next) => {
    const {
      onStart, convertFile, convertPdf, user: {total_file_size: totalFileSize,
      total_file_size_on_this_cycle: totalAllCycle,
      max_storage: maxStorage, total_file_size_limit: totalFileSizeLimit }, oneGb,
      uploadedDataInfo, currentLimitVideo, type: projectType, currentTotalSize
    } = this.props;
    const { type } = file;
    const { usedDataSize, maxUploadSize, membershipPlanName } = uploadedDataInfo;
    var file_size = parseInt(file.size);

    if (
        Shared.isVideoType(projectType) && !MIME_ALLOWED_UPLOAD_WITH_VIDEO_TYPE.includes(type) ||
        !Shared.isVideoType(projectType) && !MIME_ALLOWED_UPLOAD_WITH_WEB_TYPE.includes(type)
      ) {
      this.clearInputFileAndShowPopup('形式が異なる為、アップロードできません。');
      return;
    }

    if (['image/png', 'image/jpeg', 'image/gif'].includes(type) && file_size > _10_MB) {
      this.clearInputFileAndShowPopup(`1回の容量を超過している為、アップロードできません。`)
      return;
    }

    if (currentTotalSize + file_size > _10_GB && membershipPlanName.includes('Free')) {
      this.clearInputFileAndShowPopup(`${membershipPlanName}プランのデータ容量（10 GB）を超過しました。`)
      return;
    }

    if (usedDataSize + file_size > maxUploadSize) {
      this.clearInputFileAndShowPopup(
        I18n.t(
          'shared.warning_over_upload_limit',
          {
            membership_plan_name: membershipPlanName,
            max_upload_size: Shared.formatBytes(maxUploadSize),
            grantcharts_link: Routes.owner_projects_path(),
            new_subscription_link: Routes.owner_total_info_path()
          }
        ), 'error'
      )
      return;
    }

    if ((file_size / oneGb > currentLimitVideo) || file_size + Number(totalAllCycle) > Number(totalFileSizeLimit) ||
      file_size + Number(totalFileSize) > Number(maxStorage) ) { return }

    if (type == 'video/mp4') {
      if (file_size > _3_GB) {
        this.clearInputFileAndShowPopup(`1回の容量を超過している為、アップロードできません。`)
        return;
      }
    } else if ((type == 'video/x-msvideo' || type == 'video/avi' || type.includes('wmv') || type === 'video/quicktime')) {
      if (file_size <= _3_GB) {
        this.setState({ convert_file: true });
        window.time = (((file_size / 1024) * 60000) / (40 * 1024)) + 10000
        convertFile(true);
      } else {
        this.clearInputFileAndShowPopup(`1回での動画アップロード上限（3GB）<br>を超過した為、アップロードできません。`)
        return;
      }
    } else if (type == 'application/pdf') {
      if (file_size <= (10 * _10_MB)) {
        this.setState({ showProgressBar: true })
        this.calculateTime(file_size)
        return convertPdf(file)
      } else {
        this.clearInputFileAndShowPopup(`1回でのPDFアップロード上限（100MB）<br>を超過した為、アップロードできません。<br>データ圧縮後、再度お試しください。`)
        return;
      }
    }

    onStart(file)
    next(file)
  }

  handleUpload = () => {
    const checkUploadNewPage = $('#uploadNewPagePopup').attr('data-upload-new') == 'true';
    const pageId = checkUploadNewPage ? 'new' : $('#uploadVersionPopup').attr('data-page-id');
    if (pageId === 'new' || pageId == undefined){
      this.uploader.uploadFile();
    }else {
      this.apiClient.get(`${pageId}/check_final_version`).then((response) => {
        if(response.data.is_has_final_version){
          Popup.confirm('現在、このページには最終稿バージョンが存在します。<br>新しいバージョンをアップロードすると、最終稿が解除されます。<br>アップロードしてよろしいですか？', {
            confirmText: 'OK',
            cancelBtn: false,
            success: () => {
              this.uploader.uploadFile();
            },
          });
        }else{
          this.uploader.uploadFile();
        }
      })
      .catch(() => {
        Popup.alert('画像のアップロードに失敗しました。');
      })
    }
  }

  handleFileChange = (e) => {
    const fileName = e.target.files[0].name;
    $('#show-text-uploader-akaire').text(fileName);
    $('#show-text-uploader-akaire').addClass('font-weight-bold');
  }

  render() {
    const { baseURL, projectId, isOpen, onClickClose, onStart, onFinish, onError, type, onClickInputFile, is_mobile } = this.props;
    const { completed, showProgressBar } = this.state
    var title = '';
    var progressBar = '';
    let divText = '';
    var icon_popup = '';
    if (['動画', 'video'].includes(type)) {
      title = 'YouTube動画を赤入れする';
      icon_popup = (IconUploadVideo)
      divText = !is_mobile ? (<div className="font-size-14px mt-2 black-text">
                  <span>※動画アップロードする場合、</span><div></div>
                  <span className="pl-42px">WMV・AVI・MOV形式データは「MP4形式」で保存されます。</span><br />
                  <span className="pl-42px">アップロード後、WMV・AVI形・MOV式でダウンロードする場合は</span><br />
                  <span className="pl-42px">指定のファイル形式でダウンロード してください。</span><br />
                </div>) : (
                  <div className="font-size-14px mt-2 font-weight-bold">
                    <span>現在表示可能な動画形式は WMV / AVI / MP4 / MOVになります。</span>
                  </div>
                )
    }
    else {
      title = '画像またはPDFを赤入れする';
      icon_popup = (IconUploadImage)
      divText = <div className="black-text mt-2">
                   <span>※現在表示可能な画像形式は PDF / JPG / PNG / GIFになります。</span>
                </div>
    }
    if (showProgressBar) {
      progressBar = (
        <Progress value={completed}>{completed}%</Progress>
      )
    }
    return (
      <Modal isOpen={isOpen} toggle={onClickClose} ref={node => (this.modalRef = node)} className="animation-uploader-modal modal-dialog-centered modal-style additional-akaire">
        <ModalBody>
          <div className='justify-content-center text-center'>
            <span className='additional-akaire-title'>
              <img className="icon-upload filter-black-icon" src={icon_popup} alt="アップロード" width="50" />
              {['動画', 'video'].includes(type) ? '動画データを赤入れする' : '画像またはPDFを赤入れする'}</span>
          </div>
          {progressBar}
          <div className="align-center-div mt-3">
            <label className="btn btn-default btn-file">
            ファイルを選択
            <ReactS3Uploader
              preprocess={this.preprocess}
              onProgress={this.onUploadProgress}
              signingUrl={`${baseURL}/animations/new`}
              accept={['動画', 'video'].includes(type) ? "video/mp4,video/mov,video/avi,video/x-ms-wmv,video/quicktime,video/x-msvideo" : "image/png,image/jpeg,image/gif,application/pdf"}
              uploadRequestHeaders={{ 'x-amz-acl': 'private' }}
              onFinish={this.onFinish }
              inputRef={_ => this.fileInput = _}
              onClick={ () => onClickInputFile() }
              autoUpload={false}
              scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
              ref={uploader => { this.uploader = uploader; }}
              onChange={this.handleFileChange}
            />
            </label>
            <span id="show-text-uploader-akaire" className="ml-2 text-black">選択されていません</span>
            { divText }
          </div>
          <div className="additional-akaire-button text-center">
            <Button color="primary" className="" onClick={this.handleUpload}>Uploadする</Button>
            <Button color="secondary" className="text-dark btn-button-close" onClick={onClickClose}>閉じる</Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
};
