// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../src/style.scss';
import '../src/css/icofont.min.css';
import '../src/css/LineIcons.css';
import '../src/css/viewer.min.css';

import DetailInvoice from './detail_invoice'
import SearchForm from './search_form'
import LogWorkAction from './log_work_action'
import QuotationTask from './quotations/quotation_task'
import ChangeText from './change_text'
import RegisterKeycode from './register_keycode'
import RegisterPage from './register_page'
import CheckPolicy from './check_policy'
import Visiable from './visible'
import TotalInfo from './total_info'
import VisitContact from './visit_contact'
import ProfilePage from './profile_page'
import NewProject from "./new_project";
import DetailProject from "./detail_project";
import InviteUserPage from "./invite_user_page";
import CompanyProfilePublicationStatus from "./company_profile_status_publication"
import ContractAction from "./contract_action"

import ItemEachPage from "./item_each_page"
import Quotation from './quotations/quotation'
import Order from './classes/order'
import DashboardToggle from './dashboard_toggle'
import Chat from './chats/chat'

import Shared from './shared'
import Input from './shared/input'
import Popup from './shared/popup'
import Notify from './shared/notify'
import Disabled from './shared/disabled'
import Required from './shared/required'
import Modal from './shared/modal'
import BankAccount from "./bank_account";
import ProjectInfo from './projects/project_info'
import PortfolioPage from './portfolio_page'
import Project from './project'
import DefaultAction from "./default_action";
import I18n from './i18n.js.erb'
import Task from './tasks/task'
import TaskPic from './tasks/task_pic'
import Collapsible from './shared/collapsible'

import ProjectPage from "./project_page"
import WorkCopy from './works/work_copy'
import OrderNew from './orders/order_new'
import DashboardClientModal from './dashboard_client_modal'
import Ticket from './works/ticket'
import Discussion from './discussions/discussion'
import PaymentTermRevision from './classes/payment_term_revision'
import MessageKeep from './message_keep'
import Client from './companies/client'
import ActionInvalid from './action_invalid'
import MessageStatus from './message_status/message_status'
import Subscription from './subscriptions/subscription'
import Page from './pages_handle_choice_contract_type'
import WorkQuantity from './work_quantity/work_quantity'
import Supplier from './companies/supplier'
import User from './user'
import PasswordReset from './password_reset'
import InvoicePayment from './invoice_payment'
import Campaign from './campaign'
import CheckList from './works/check_list'
import CollabStatus from './collaborator/collab_status'
import ClientStatus from './client/client_status'
import SupplierStatus from './supplier/supplier_status'
import HandleUrl from './handle_url';
import SettingPaymentMethod from './setting_payment_method';
import PersonInchargeTicket from './works/person_incharge_ticket'
import Signature from './signature'
import Animation from './animation'
import RegistrationTaxNumber from './companies/registration_tax_number'
import SortFunction from './sortable/sort_function'
import SideBar from './sidebar'
import AkaireFile from './akaire_file/akaire_file'
import ListTempFileJs from './list_temp_file_js'
import PicPartNer from './pic_partner';
import CompanyIpLimit from './company_ip_limit';

require("jquery")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@nathanvda/cocoon")
require("popper.js").default
require("bootstrap")
require("feather-icons")
require("packs/viewer.min")
require("packs/custom")
require("packs/plan_search")
require("packs/search")
require("packs/admins/bootstrap_select")
require("moment")
require('jquery-serializejson')
require('packs/jquery-ui.min')
require('packs/jquery.ui.touch-punch.min')
    // require("packs/hover")
    //= require jquery-ui/autocomplete
    //= require jquery-ui
    //= require moment
    //= require select2

import * as Routes from './routes.js.erb';
// reload file routes.js.erb to reload routes = open file and ctrl + S
window.Routes = Routes;

import toastr, { warning } from 'toastr'
window.toastr = toastr
window.defaultAction = new DefaultAction;
window.I18n = I18n;

window.DetailInvoice = new DetailInvoice;
window.SearchForm = new SearchForm;
window.logWorkAction = new LogWorkAction;
window.QuotationTask = new QuotationTask;
window.ChangeText = new ChangeText;
window.RegisterKeycode = new RegisterKeycode;
window.RegisterPage = new RegisterPage;
window.Visiable = new Visiable;
window.Quotation = new Quotation;
window.Disabled = new Disabled;
window.Required = new Required;
window.Order = new Order;
window.Shared = new Shared;
window.Input = new Input;
window.Popup = new Popup;
window.Notify = Notify;
window.Modal = new Modal;
window.CheckPolicy = new CheckPolicy;
window.DashboardToggle = new DashboardToggle;
window.TotalInfo = new TotalInfo;
window.VisitContact = new VisitContact;
window.ItemEachPage = new ItemEachPage;
window.Chat = new Chat;
window.BankAccount = new BankAccount
window.ProjectInfo = new ProjectInfo;
window.PortfolioPage = new PortfolioPage;
window.I18n = I18n;
window.Task = new Task;
window.TaskPic = new TaskPic;
window.WorkCopy = new WorkCopy;
window.ProfilePage = new ProfilePage
window.NewProject = new NewProject;
window.ProjectPage = new ProjectPage;
window.DetailProject = new DetailProject;
window.Project = new Project;
window.InviteUserPage = new InviteUserPage
window.OrderNew = new OrderNew;
window.Collapsible = new Collapsible
window.DashboardClientModal = new DashboardClientModal;
window.Ticket = new Ticket;
window.Discussion = new Discussion;
window.PaymentTermRevision = new PaymentTermRevision;
window.MessageKeep = new MessageKeep;
window.Client = new Client
window.CompanyProfilePublicationStatus = new CompanyProfilePublicationStatus
window.ContractAction = new ContractAction
window.ActionInvalid = new ActionInvalid
window.MessageStatus = new MessageStatus;
window.Subscription = new Subscription;
window.Page = new Page;
window.WorkQuantity = new WorkQuantity;
window.Supplier = new Supplier;
window.User = new User;
window.PasswordReset = new PasswordReset;
window.InvoicePayment = new InvoicePayment;
window.Campaign = new Campaign;
window.CheckList = new CheckList;
window.CollabStatus = new CollabStatus;
window.ClientStatus = new ClientStatus;
window.SupplierStatus = new SupplierStatus;
window.HandleUrl = new HandleUrl;
window.SettingPaymentMethod = new SettingPaymentMethod;
window.PersonInchargeTicket = new PersonInchargeTicket;
window.Signature = new Signature;
window.Animation = new Animation;
window.RegistrationTaxNumber = new RegistrationTaxNumber;
window.SortFunction = new SortFunction;
window.SideBar = new SideBar;
window.AkaireFile = new AkaireFile;
window.ListTempFileJs = new ListTempFileJs;
window.PicPartNer = new PicPartNer;
window.CompanyIpLimit = new CompanyIpLimit;

require("ckeditor/config")
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from 'react';
import { render } from 'react-dom';
import 'react-mfb/mfb.css';

import AnimationInspector from '../src/components/AnimationInspector';
import AdminAnimationInspector from '../src/components/AdminAnimationInspector';
import RegistrationModal from '../src/components/RegistrationModal';
import NotifyModal from '../src/components/NotifyModal';
import CreateNewAkaire from '../src/components/CreateNewAkaire';
import ListTempFile from '../src/components/ListTempFile';
import {showPopupChatAkaireFileWhenClickFromEmail} from './show_popup_chat_from_mail';
import CompareVersion from '../src/components/CompareVersion';
import '../src/tooltip';
import '../src/userAgent';
import '../src/confirmExternalLinks';

import '../src/css/application.scss';
import './dowload_file';

const components = [
  {
    selector: 'animation-inspector-container',
    Component: AnimationInspector
  },
  {
    selector: 'admin-animation-inspector-container',
    Component: AdminAnimationInspector
  },
  {
    selector: 'registration-modal-container',
    Component: RegistrationModal
  },
  {
    selector: 'notify-modal-container',
    Component: NotifyModal
  },
  {
    selector: 'new-akaire-rework',
    Component: CreateNewAkaire
  },
  {
    selector: 'list_temp_file',
    Component: ListTempFile
  },
  {
    selector: 'show-compare-version-akaire',
    Component: CompareVersion
  }
];

document.addEventListener('DOMContentLoaded', () => {
  components.forEach(({ selector, Component }) => {
    const container = document.getElementById(selector);
    container && render(<Component {...JSON.parse(container.dataset.props || null)} />, container);
  });
});

function showPopup(name, popupType) {
  const popup = new Popup;
  if (popupType == 'success') {
    if (!!name && !!name.val()) {
      popup.confirm('', {
        description: `<div class="font-weight-bold">
          書類名：${name.val()}　を発注しました。<br>
          発注した注文書をダウンロードしますか？<br>
          ※注文書PDFは発注書一覧からもダウンロードできます。
        </div>`,
        confirmText: 'ダウンロードする',
        cancelText: '後にする',
        width: 'auto',
        success: () => {
          window.open(
            Routes.owner_show_pdf_sender_documents_path('pdf', { order_id: name.attr('data-order-id') }),
            "_blank"
          );
        }
      })
      history.replaceState(null, null, window.location.pathname);
    }
  } else if (popupType == 'drafted' && !!name) {
    popup.alert(I18n.t(`order.create.${popupType}`, { supplier_name: name }))
    history.replaceState(null, null, window.location.pathname);
  }
}

function formatState (state) {
  const option = $(state.element);
  const className = option.data("class");

  if (!className) {
    return $('<span class="select2_invoice_feature">' + state.text + '</span>');
  }

  return $(`<span class="${className}">${state.text}</span>`);
};

$(document).ready(function(){
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    $('table.search-list').find('.dropdown-new-stype').removeClass('check-right')
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  $(document).on('click', 'a', function(e) {
    if ($(this).attr('href').includes('/akaire_feature/akaire_files')) {
      e.preventDefault();
      window.open($(this).attr('href'));
    }
  })

  $("#modalSelectCompanyEnterPayment").on("hidden.bs.modal", function () {
    const invoicepayment = new InvoicePayment
    invoicepayment.setCurrentCompany()
  });
  showPopupChatAkaireFileWhenClickFromEmail()
  showPopup($('#order_name_when_submitted'), 'success')
  showPopup($('#draft_order_receiver').val(), 'drafted')

  count_discussion_search_condition()
  newQuotation();
  checkSearchForm();
  closePopUp();
  showPopUpNew();
  $( '.select2-cc' ).select2()
  $( '.select2-cc-filter' ).select2({
      dropdownParent: $('.check_select2'),
      templateResult: formatState
  })
  $('.select2-cc-invoice').select2({
    dropdownParent: $('#search-popup-invoice'),
    templateResult: formatState
  })
  $('.select2-cc-customize-class').select2({
    templateResult: formatState,
    templateSelection: formatState,
  })
  clostPopupChangeStatusTask()

  //show_popup
  $("#show-popup").modal("show")
  window.$ = $
  defaultAction.run()

  $( '.btn-add-url-order-send' ).click( function ()
  {
    window.history.pushState( "object or string", "Title", "/documents/submission/purchasing/new" );
  } )

  $( '.btn-remove-url-order-send' ).click( function ()
  {
    let currentUrl = document.URL.replace( /^.*\/\/[^\/]+/, '' );
    if ( currentUrl.includes( "new" ) )
    {
      window.history.pushState( "object or string", "Title", "/documents/submission/purchasing/info" );
    }
    else
    {
      window.history.pushState( "object or string", "Title", currentUrl );
    }
  } )

  $( '.button-info' ).click( function ()
  {
    let currentUrl = document.URL.replace( /^.*\/\/[^\/]+/, '' );
    if ( currentUrl.includes( "new" ) )
    {
      window.history.pushState( "object or string", "Title", "/documents/submission/purchasing/info" );
    }
    else
    {
      window.history.pushState( "object or string", "Title", currentUrl );
    }
  } )

  $( "#modal_invoice_orders" ).on( "hide.bs.modal", function ()
  {
    let currentUrl = window.location.pathname;
    if ( currentUrl.includes( "/documents/submission/payment" ) )
    {

      window.history.pushState( "", "", "/documents/submission/payment/info" );
    } else if ( currentUrl.includes( "/documents/submission/sale/invoice" ) )
    {

      window.history.pushState( '', '', Routes.owner_list_invoices_path() );
    }
  } )

  $("#link-task-new").on('click', function() {
    const url = '/projects/'+$(this).attr('data-id')+'/new';
    window.history.pushState( '', '', url );
  });

  $( "#task-new" ).on( "hide.bs.modal", function ()
  {
    let currentURL = window.location.pathname;
    const REGEXP_URL = /projects\/(\d+)\/new$/
    if ( currentURL.match(REGEXP_URL) )
    {
      let url = '/projects/'+ currentURL.match(REGEXP_URL)[1] + '/task'
      window.history.pushState( "", "", url );
    }
  } )

  $( "#link-task-new" ).on( 'click', function ()
  {
    const url = '/projects/' + $( this ).attr( 'data-id' ) + '/new';
    window.history.pushState( '', '', url );
  } );

  $( "#task-new" ).on( "hide.bs.modal", function ()
  {
    let currentURL = window.location.pathname;
    const REGEXP_URL = /projects\/(\d+)\/new$/
    if ( currentURL.match( REGEXP_URL ) )
    {
      let url = '/projects/' + currentURL.match( REGEXP_URL )[ 1 ] + '/task'
      window.history.pushState( "", "", url );
    }
  } )

  $("#link-task-new").on('click', function() {
    const url = '/projects/'+$(this).attr('data-id')+'/new';
    window.history.pushState( '', '', url );
  });

  $( "#task-new" ).on( "hide.bs.modal", function ()
  {
    let currentURL = window.location.pathname;
    const REGEXP_URL = /projects\/(\d+)\/new$/
    if ( currentURL.match(REGEXP_URL) )
    {
      let url = '/projects/'+ currentURL.match(REGEXP_URL)[1] + '/task'
      window.history.pushState( "", "", url );
    }
  } )

  $(document).on('hidden.bs.modal', '.modal.modal_expiration', function(e) {
    $(e.target).find('input.datetimepicker').val('')
    $(e.target).find('input.expiration-time').val('')
  })

  $(document).on('hide.bs.modal', '.person_incharge_modal_of_ticket_member', function(event){
    const pic = new PersonInchargeTicket;
    const modalTicketMember = $('#personInChargeOfObject')
    if (((modalTicketMember.attr("data-is-change") === "true") || (modalTicketMember.attr('is-send-mail') == 'true')) &&
          modalTicketMember.attr('data-is-show') === 'true')  {
      event.preventDefault();
      pic.confirmCloseModalSettingMember()
    }
  })

  $(document).on('hide.bs.modal', '#addPersonInChargeOfObject', function(event){
    const modalTicketMember = $('#personInChargeOfObject')
    const modalAddMember = $('#addPersonInChargeOfObject')
    if (modalAddMember.attr('data-is-back-list-member') === 'true') {
      modalTicketMember.modal('show')
    }
  })

  $(document).on('hide.bs.modal', '#inviteByEmail', function(event) {
    const modalTicketMember = $('#addPersonInChargeOfObject')
    const isShowListmember = modalTicketMember.attr('data-is-back-list-member')
    const dataIsShowListmember = $('#inviteByEmail').attr('data-is-show-list-member')
    if (isShowListmember && dataIsShowListmember === 'true') {
      modalTicketMember.attr('data-is-back-list-member', 'true')
    }
  })

  if ( document.querySelector( "#show_error_modal" ) != null )
  {
    $( "#errorModal" ).modal( 'show' );
  }

  $(document).on('click', '.show_unauthorized_and_buy_plan_modal, #show_unauthorized_and_buy_plan_modal', function(e) {
    e.preventDefault();
    $(this).attr('href', '#')
    $("#errorModal").modal('show');
  })

  const url = new URL(window.location.href)
  const originParam = url.searchParams.get('origin')
  if (window.location.pathname == Routes.owner_signin_path() && originParam == 'akapon') {
    const popup = new Popup
    popup.alert(
      `Create Cloudとの統合により、ログインURLが<br>
       <a href="${Routes.owner_signin_path()}">${`${url.origin}${Routes.owner_signin_path()}`}</a>　に変更となります。`
    )
  }

  if (gon.show_popup_moving_data) {
    $('#errorModalDataIsMoving').modal('show')
  }

  setTimeout(function() {
    $('input[autocomplete="off"]').each(function() {
      if (!$(this).attr('value') && $(this).attr('value') !== 0) $(this).val('').trigger('change');
    })
  }, 1200)

  dragInArea('dragSearchOnPc')

  if ($(".container-search-table table.search-list").length > 0) {
    $(".container-search-table table.search-list").css('width', $('.container-search-table table.search-list tbody').width())
  }

  $(".container-search-table").scroll(function(){
    $(".container-search-table .dropdown-new-stype:not(.drop-right)").each(function(){
      $(this).css("left", $(this).parent().position().left - $('.container-search-table').scrollLeft());
    });
  });

  if(gon.is_notify_release_akaire_function){
    const popup = new Popup
    const tutorialRelease0304Url = gon.is_plan_akapon ? 'https://kanritools.com/support/release/20240304/' : 'https://createcloud.jp/support/release/20240304/'
    popup.alert(`<div class='max-width-popup-notify-akaire-function' style='text-align: start; margin: 0 auto;'>2024年3月4日に下記機能が追加されました。<br>・ファイル一覧にサムネイル形式が追加されました。<br>・ファイル一覧にガントチャート機能が追加されました。<br>・ファイル情報ページが追加されました。<br><span class='font-weight-bold'>操作方法などについては<a class='text-underline off-focus-visible font-weight-bold' href='${tutorialRelease0304Url}' target='_blank'>こちら</a>をご覧ください。</span>`)
  }
} )

$(document).on('hidden.bs.modal', function () {
  if ($('.modal:visible').length) {
    $('body').addClass('modal-open');
  }
});

$( document ).on( 'turbolinks:load', function ()
{
  $( '.preloader' ).fadeOut();
} )

function clostPopupChangeStatusTask() {
  $(document).on('click', function(e) {
    if (!$(e.target) || !$(e.target).attr('class')) return
    const classWithEle = $(e.target).attr('class')
    if (classWithEle.includes('li-status-of-task') || classWithEle.includes('show-color-of-status-task')) {
      return
    }
    const task = new Task
    if ($('#change-status-of_task-new').find('.taskStatusPpopupLi').hasClass('d-none')) return
    task.showListStatusTask($('#change-status-of_task-new'))
  })
}

function showPopUpNew() {
  const REGEXP_URL = /projects\/(\d+)\/new$/
  if ( window.location.pathname.match(REGEXP_URL) && $("#link-task-new").length > 0)
  {
    $("#link-task-new").click();
  }
}

function closePopUp() {
  $(document).on('mouseup', function(e) {
    let searchContainer = $('.search_popup')
    if (!searchContainer.is(e.target) && !isInvoiceFeature(e) && searchContainer.has(e.target).length === 0 &&
        ($('body').hasClass('popup-on') || $('#search_invoice_childs').hasClass('popup-on2') || $('#search_invoice_works').hasClass('popup-on2')) ){
      $('body').removeClass('popup-on');
      $('#search_invoice_childs').removeClass('popup-on2')
      $('#search_invoice_works').removeClass('popup-on2')
    }
  })
}

function isInvoiceFeature(e) {
  return [
    e.target.className,
    !!e.target.firstChild ? e.target.firstChild.className : ''
  ].includes('select2_invoice_feature')
}

function count_discussion_search_condition() {
  const countFilter = $('.discuss-filter')
  let countNumber = parseInt(countFilter.text())
  let create_by = 0
  let status_in_going_on = 0
  let status_in_end = 0
  let countFlg = $('#countFlg-discussion')

  if ($('#q_created_by_eq').val() != '') {
    create_by = 1
    countFlg.text('selected')
  }

  if ($('#q_status_in_going_on').prop('checked') != false) {
    status_in_going_on = 1
  }

  if ($('#q_status_in_end').prop('checked') != false) {
    status_in_end = 1
  }

  countNumber = create_by + status_in_going_on + status_in_end
  countFilter.text(countNumber)
}

function checkSearchForm(){
  if($('.js-show_invoice_search_form').is(':checked') || $('#switch1').is(':checked')){
    window.Visiable.show('.search-form')
  }
}

function newQuotation(){
  if (!document.querySelector('#quotations')) return;
  changeSelectPlan($('#quotation_contract_sub_type').val())

  if ($('tbody.quotation_item_table').find('.remove_fields').length == 1) {
    $('tbody.quotation_item_table').find('.remove_fields').addClass('disabled')
  }

  // remove quotation item

  $('table.table-qt').on("cocoon:after-remove", function() {
    let total_before_tax = 0;
    let total_tax = 0;
    let total_after_tax = 0;

    // $('.js-plan_item').each(function() {
    //   if ($('.' + $(this).attr('id_element')).length == 0)
    //     $(this).remove()
    // })

    $('tbody.quotation_item_table').find('tr').each(function(){
      if ($(this).attr('id_element') && $('.' + $(this).attr('id_element')).length == 0) {
        $(this).remove()
      } else if (!$(this).hasClass('js-plan') && $(this).attr('style') !== 'display: none;') {
        let tax_select = $(this).find('select.consumption-tax');
        let tax_input = $(this).find('input.tax-input');
        let amount = $(this).find('input.amount').val();
        let quantity = $(this).find('input.quantity').val();
        let price_before_tax = parseFloat(amount) * parseInt(quantity);
        let caculate_tax = caculateTax(tax_select.val(), price_before_tax, tax_input.val())
        total_before_tax += Number(caculate_tax[0].toFixed());
        total_tax += caculate_tax[1];
        total_after_tax += Number(caculate_tax[2].toFixed());
      }
    })
    $('#amount_before_tax').val(total_before_tax);
    $('#tax').val(total_tax);
    $('#amount_after_tax').val(total_after_tax).trigger('change');
    total_before_tax = total_before_tax - parseInt(total_before_tax) > 0 ? total_before_tax.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) : new Intl.NumberFormat('ja-JP', {}).format(total_before_tax)
    total_tax = total_tax - parseInt(total_tax) > 0 ? total_tax.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) : new Intl.NumberFormat('ja-JP', {}).format(total_tax)
    total_after_tax = total_after_tax - parseInt(total_after_tax) > 0 ? total_after_tax.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) : new Intl.NumberFormat('ja-JP', {}).format(total_after_tax)
    $('#text_amount_before_tax').text(`${total_before_tax}円`);
    $('#text_tax').text(`${totalAllTax()}円`);
    $('#text_amount_after_tax').text(total_after_tax + '円')

    if ($('tbody.quotation_item_table').find('.remove_fields').length == 1) {
      $('tbody.quotation_item_table').find('.remove_fields').addClass('disabled')
    } else {
      $('tbody.quotation_item_table').find('.remove_fields').removeClass('disabled')
    }
  });

  // add quotation item

  $('table.table-qt').on("cocoon:after-insert", function() {
    $('table.table-qt').find('.js-disabled').removeClass('disabled')
    switch ($('#quotation_contract_sub_type').val()) {
      case 'by_number':
      case 'by_time':
        $('input.quantity').each(function() {
          $(this).addClass('disabled').val(1)
          $(this).parent('td').find('span').text(1)
        })
        $('input.unit').each(function() {
          $(this).addClass('disabled').val($('#quotation_contract_sub_type option:selected').text())
        })
        break;
      case 'by_hours':
      case 'fixed_quantity':
        $('input.quantity').each(function() {
          $(this).removeClass('disabled')
        })
        $('input.unit').each(function() {
          $(this).addClass('disabled').val('時間')
        })
        break;
      default:
        break;
    }
    if ($('tbody.quotation_item_table').find('.remove_fields').length == 1) {
      $('tbody.quotation_item_table').find('.remove_fields').addClass('disabled')
    } else {
      $('tbody.quotation_item_table').find('.remove_fields').removeClass('disabled')
    }
  });
}

function caculateTax(status, price, tax){
  let amount_before_tax = parseFloat(price)
  let amount_after_tax = parseFloat(price)
  let taxF = parseFloat(tax)
  let amount_tax = 0
  switch (status) {
    case 'internal_tax':
      amount_tax = amount_after_tax * (1 - 1/(1 + taxF/100))
      amount_before_tax = amount_after_tax - amount_tax
      break;
    case 'external_tax':
      amount_tax = amount_before_tax * taxF/100
      amount_after_tax = amount_before_tax + amount_tax
      break;
    default:
      amount_tax = 0
  }
  return [amount_before_tax, amount_tax, amount_after_tax]
}

function changeSelectPlan(contract_sub_type){
  $.ajax({
    method: 'GET',
    url: "/quotations/change_list_plans",
    dataType: 'json',
    data: { contract_sub_type: contract_sub_type},
    headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  }).done(function(data) {
    $('select#plan_id').empty().append("<option value></option>");
    let plans = data.plans
    if (data.plans.length > 0) {
      for (let i = 0; i < data.plans.length; i++) {
        $('select#plan_id').append('<option value="' + plans[i].id +  '">' + plans[i].name + '</option>');
      }
    }
  })
}

function totalAllTax() {
  let totalTax = 0
  $('tbody.quotation_item_table').find('tr:not([style*="display: none"])').find('.tax-money').toArray()
  .forEach(tax => { totalTax += Number($(tax).text()) })
  return totalTax;
}

function dragInArea(elementId) {
  let mouseDown = false;
  let startX, scrollLeft;
  const slider = document.getElementById(elementId);
  if (!slider) {
    return;
  }

  const startDragging = (e) => {
    e.stopPropagation();
    mouseDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  }

  const stopDragging = (e) => {
    e.stopPropagation();
    mouseDown = false;
  }

  const move = (e) => {
    e.preventDefault();
    if(!mouseDown) { return; }
    const x = e.pageX - slider.offsetLeft;
    const scroll = x - startX;
    slider.scrollLeft = scrollLeft - scroll;
  }

  slider.addEventListener('mousemove', move, false);
  slider.addEventListener('mousedown', startDragging, false);
  slider.addEventListener('mouseup', stopDragging, false);
  slider.addEventListener('mouseleave', stopDragging, false);
}
