import React, { Component } from 'react';
import { Card, CardBody, CardText, CardLink } from 'reactstrap';
import moment from 'moment';
import nl2br from 'react-nl2br';
import classnames from 'classnames';

import { renderByFileUrl } from '../util/buildUrl';

import ShowShape from './ShowShape';
import { positionStyle } from '../util/animation-marker';

export default class AnimationMarkersInPlayer extends Component {
  render() {
    const { markers, visibility, onMarkerUpdationCalled, image, updateMakerShow } = this.props;
    return (
      <div className="animation-markers-in-player">
        {
          visibility !== 'none' && markers.map(({ body, x, y, width, height, shape, animation_marker_files: files, id, text_color, text_underline, text_weight, border_color, number_comment}, i) => {
            const cardStyle = positionStyle({ x, y, width, height })
            cardStyle.textDecoration = text_underline ? 'underline' : 'none'
            cardStyle.fontWeight = text_weight
            cardStyle.borderColor = border_color
            cardStyle.borderWidth = '5px'
            cardStyle.borderStyle = 'solid'
            return (
              <div className="animation-marker" key={i}>
                <ShowShape x={x} y={y} width={width} height={height} shape={shape} marker_id={id} onMarkerUpdationCalled={onMarkerUpdationCalled} image={image} updateMakerShow={updateMakerShow} borderColor={border_color} index={number_comment} />
              </div>
            );
          })
        }
      </div>
    );
  }
};
