export default class Campaign {
  validateDiscountValueForAdmin(element) {
    const $ele = $(element);
    const discountValue = Number($ele.val());
    if (discountValue > 100 || discountValue < 0) {
      $ele.val('')
    }
  }

  validateDiscountValue(element) {
    const $ele = $(element);
    const discountValue = Number($ele.val());
    if (discountValue > 20 || discountValue < 0) {
      $ele.val('')
    }
  }

  submitCreateCampaign() {
    $("#addCampaignModal").find(".submit-button").attr("disabled", true)
    $("#addCampaignModal").find(".submit-create-form").click()
  }

  showEditCampaignModal(campaign_id) {
    $(".text-danger.render-by-js").remove();
    const modal = $("#editCampaignModal")
    const url = Routes.admins_campaign_path(campaign_id);
    const callAjax = Shared.callAjax(url, {}, 'json', 'GET');
    callAjax.done(function(data) {
      modal.find('#campaign_company_name').val(data.company_name)
      modal.find('#campaign_expired_date').val(data.expired_date)
      modal.find('#campaign_discount_value').val(data.discount_value)
      modal.find('#campaign_id').val(data.id)
      modal.find('.submit-button').attr('disabled', false)
      modal.modal('show')
    })
  }

  showModalCreateCampaignSP(e) {
    e.stopPropagation();
    $('.button-create-campaign').trigger('click')
    setTimeout(() => {
      $('.fm_item_outer').show()
    }, 200)
  }
}
