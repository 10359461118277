import React, { Component } from 'react';
import { Range } from "../ui"

export default class  AnimationMarkerLine extends Component  {
  constructor() {
    super();
  }

  highLightComment = (id, time, period_end) => {
    this.props.isEmbedVideo ? this.props.player.seekTo(time, true) : this.props.player.seek(time);
    if (period_end) {
      this.props.setPeriodRangeWhenClickTimeLine([parseFloat(time) * 1000, parseFloat(period_end) * 1000])
    } else {
      this.props.setPeriodRangeWhenClickTimeLine([])
      $(".items-animate .item-akapon").css("background-color", "#fff");
      var items_comment = document.getElementsByClassName("items").item(0);
      var comment_item = document.getElementsByClassName("animation-marker-" + id).item(0);
      items_comment.scrollTop = comment_item.offsetTop - items_comment.offsetTop;
      $(".animation-marker-" + id).trigger("click");
      $(".animation-marker-" + id).css("background-color", "rgb(245,151,148)");
      $(".animation-comment-pane .item-akapon").css("display", "none");
    }
  }

  isVideo(){
    const { project_type } = this.props;
    return ['動画', 'video'].includes(project_type)
  }

  render() {
    const {markers, isShowDrawComponent} = this.props;
    const duration = (this.props.player != undefined && this.props.player.state != null) ? this.props.player.state.totalDuration : this.props.duration;

    return(
      <div className='comment-timeline-box'
        style={{width:"100%",
        bottom:0,left:0}}>
          <div style={{display:"flex"}}>
            <p className="title-comment-line">コメント</p>
            <div className="comment-timeline">
                <div className="animation-marker-line"style={{borderTop:"5px solid white"}}></div>
                <div className="comment-marker-points-display">
                  {
                    markers.sort((el_1, el_2) => el_1.id - el_2.id).map(({ seek_time: time, border_color, period_end, id }, i) => {
                      return (
                        <div className="comment-marker-point" onClick={() => isShowDrawComponent ? null : this.highLightComment(id, time, period_end)}
                          style={{ left: `calc(${time / duration * 100}% - 4px)`, backgroundColor:  border_color}}
                          key={i}>
                        </div>
                      );
                    })
                  }
                </div>
            </div>
          </div>

          {/* {
            this.props.isShowMobile ? (
              <div id="slider-scale-box" className="container-scale-box">
                <p className="title-scale-line">拡大縮小</p>
                <div className='slider-scale-box' style={{position: "relative"}}>
                  <Range
                    containerClass={"ml-3"}
                    className={"ml-1 col-12"}
                    value={scaleImage}
                    step={5}
                    handleChange={onChangeScaleImage}
                    isShowDrawComponent={isShowDrawComponent}
                  />
                </div>
              </div>
            ) : (<></>)
          } */}
            
            {/* Mock */}
          {/* <div style={{display:"flex"}}>
            <p className="title-comment-line">範囲指定</p>
            <div className="comment-timeline">
                <div className="animation-marker-line" style={{borderTop: "5px solid white"}}></div>
            </div>
          </div>
          <div style={{display:"flex"}}>
            <p style={{textAlign: "center", margin: "auto", color: "#fff", fontSize: "11px"}}>
              クリック後●スクロールで範囲指定
            </p>
          </div> */}
      </div>
    )
  }
};
