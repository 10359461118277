import React, { Component } from 'react';
import classnames from 'classnames';
import Draggable from 'react-draggable';
import { findDOMNode } from 'react-dom';

export default class ShowShape extends Component {
  handleStop =(e, ui)=> {
    const { x, y, onMarkerUpdationCalled, marker_id, image, updateMakerShow } = this.props;
    const playerRect = findDOMNode(image).getBoundingClientRect();
    const newX = (playerRect.width * x) / 10000 + playerRect.left;
    const newY = (playerRect.height * y) / 10000 + playerRect.top;
    const updateX = ((newX + ui.lastX) - playerRect.left) / playerRect.width * 10000;
    const updateY = ((newY + ui.lastY) - playerRect.top) / playerRect.height * 10000;
    const data = {
      animation_marker: {
        x: updateX,
        y: updateY
      }
    };
    updateMakerShow(updateX, updateY, marker_id)
    onMarkerUpdationCalled(data, marker_id)
  }
  render() {
    const { x, y, width, height, shape, marker_id, borderColor, index } = this.props;
    switch (shape) {
      case 'point':
        return (
          <Draggable
            position={{ x: 0, y: 0 }}
            onStop={this.handleStop}>
            <div className={`show-mark-shape marker-${marker_id}`} style={{ left: `${x / 100}%`, top: `${y / 100}%` }} data={marker_id}>
              <div className="border-none numerical-order-container d-flex justify-content-center align-items-center cursor-pointer" style={{backgroundColor: borderColor}}
                   onClick={() => $(`div.animation-marker-${marker_id}`).trigger('click')}
              >
                <div className="comment-no">
                  {index}
                </div>
              </div>
            </div>
          </Draggable>
        )
      case 'rect':
        return (
          <Draggable
            position={{ x: 0, y: 0 }}
            onStop={this.handleStop}>
            <div className={`show-mark-shape marker-${marker_id}`} style={{ left: `${x / 100}%`, top: `${y / 100}%`, width: `${width / 100}%`, height: `${height / 100}%` }} data={marker_id} onClick={() => $(`div.animation-marker-${marker_id}`).trigger('click')}>
              <div className="red-rect" style={{backgroundColor: "rgba(255, 239, 135, 0.2)", borderColor: borderColor}}>
                <div className="border-none numerical-order-container d-flex justify-content-center align-items-center m-1" style={{backgroundColor: borderColor}}>
                  <div className="comment-no">
                    {index}
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
        )
      case 'draw':
        return (
          <Draggable
            position={{ x: 0, y: 0 }}
            onStop={this.handleStop}>
            <div className={`show-mark-shape marker-${marker_id}`} style={{ left: `${x / 100}%`, top: `${y / 100}%`, width: `${width / 100}%`, height: `${height / 100}%` }} data={marker_id} onClick={() => $(`div.animation-marker-${marker_id}`).trigger('click')}>
              <div className="border-none numerical-order-container d-flex justify-content-center align-items-center m-1" style={{backgroundColor: borderColor}}>
                  <div className="comment-no">
                    {index}
                  </div>
                </div>
            </div>
          </Draggable>
        )
    }
  }
}
