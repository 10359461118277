import React, { Component } from 'react';
import moment from 'moment';
import objectToFormdata from 'object-to-formdata';
import apiClient from '../util/api-client';
import { renderByFileUrl } from '../util/buildUrl';


export default class ReplyComment extends Component {
  constructor(props) {
    super(props);
    const { replyComment: { body } } = props;
    this.state = {
      body: body,
      oldBody: body,
      shouldShowEditReply: false,
      files: [],
    };
  }
  componentDidMount() {
    this.fetchReplyComment();
  }

  openReplyDetail =(marker)=>{
    this.props.updateSelectedMarker(marker);
    if ($('.remove-pd').length > 0) $('.remove-pd').find('.widget_close_wrapper').trigger('click');
  }

  usersToInReplyComment = (replyIds) => {
    const { assignments } = this.props;
    return assignments.filter(assignment => replyIds.includes(`${assignment.value}`))
  }
  toggle = bool => name => {
    this.setState({ [`shouldShow${name}`]: bool })
    if (name === 'EditReply' && !bool) {
      this.setState({ body: this.state.oldBody })
    }
  }
  open = this.toggle(true)
  close = this.toggle(false)
  onChangeBody = ({ target: { value } }) => {
    this.setState({ body: value })
  }
  fetchReplyComment = async ()  => {
    const { replyComment: { id, parent_id: parentReplyId } } = this.props;

    return await apiClient().get(`/akaire_feature/animation_markers/${parentReplyId}/reply_comments/${id}`)
                      .then(res => {
                        this.setState({files: res.data.attachment_files})
                      })
  }
  onClickDelete(targetId) {
    return event => {
      event.preventDefault();
      const { onMarkerDeletionCalled } = this.props;
      if (!confirm("本当に削除しますか？")) return;
      onMarkerDeletionCalled(targetId);
    }
  }
  renderAttachmentFiles = () => {
    const { files } = this.state;
    return(
      <div className="attached-files">
        {
          files.map((file, i) => {
            return (
              <div className="attached-file my-2" key={i}>
                <a href={file.file_object.url} target="_blank" className="cursor-pointer">
                  {renderByFileUrl(file.file_object.url)}
                </a>
              </div>
            )
          })
        }
      </div>
    )
  }
  onSubmit = (e) => {
    e.preventDefault();
    const { replyComment: { id, parent_id: parentReplyId }, reloadMarkers , positionComment, keepPositionComment} = this.props;
    const position = positionComment(parentReplyId)
    const { body } = this.state;
    const data = objectToFormdata({
      reply_comment: {
        body: body
      }
    })
    keepPositionComment(parentReplyId, position)
    return apiClient().patch(`/akaire_feature/animation_markers/${parentReplyId}/reply_comments/${id}`, data)
                      .then(_ => {
                        reloadMarkers();
                        setTimeout(function() {
                          if (position > 0) {
                            keepPositionComment(parentReplyId, position)
                          }
                        }, 300);
                      })
                      .catch(e => console.error(e.message))
  }
  renderReplyToName = (name, isLast) => {
    return (
    <div class="d-inline-block mt-1 ml-3">
        <span class="tag_user">TO</span>
        <span>{name}さん</span>{isLast ? '' : ','}&nbsp;
      </div>
    )
  }

  formatCommentMessage(message) {
    const regex = /(ページ名：.*｜バージョン.*　に対しての返信)/g;
    return(
      <div dangerouslySetInnerHTML={{__html: message.replace(regex, '<b>$1</b>').replaceAll('\r\n', '<br>')}}></div>
    )
  }

  render(){
    const { replyComment, currentUserId, reply, userReply, parentsComment, disabledReply, shouldShowReplyButton, seen, deleteReplyMarker, user, assignments, role } = this.props;
    const { shouldShowEditReply, body, files } = this.state;
    const { reply_to: replyIds, created_at, user_id, parent_id, id } = replyComment;
    const active = !!body;
    const idDom = `reply-comments-${id}`
    const seenClass = seen ? 'bg-reply-seen' : 'bg-reply-not-seen'
    const usersToInReplyComent = this.usersToInReplyComment(replyIds)

    return(
      <div className={`reply-comment-item p-2 d-flex ${seenClass}`} id={idDom}
        onClick={()=>this.openReplyDetail(
          {
            body: body,
            id: id,
            user: user,
            created_at: created_at,
            isComment: false,
            files: files,
            parent_id: replyComment.parent_id,
            receivers: this.props.receivers,
            usersToInReplyComent: usersToInReplyComent,
            userReply: userReply,
            parentsComment: parentsComment
          }
        )}
      >
        <div className='status-field'></div>
        <div className='comment-container'>
          <div className="comment-info d-flex justify-content-between">
            <div className='d-inline-block author-name text-ellipsis truncate-name-not-period' style={{marginRight: '8px'}}>{user && user.name_in_project || '（非ログイン）'}{user && user.deleted_at ? '（解約済み）' : ''}</div>
            <div className='d-inline create-at'>{moment(created_at).format('YYYY/MM/DD HH:mm:ss', 'Asia/Tokyo')}</div>
          </div>
          <div className="comment-content">
            <div className="body message truncate line-clamp-1" dangerouslySetInnerHTML={{__html: body}}></div>
          </div>
        </div>
      </div>
    )
  }
}
