import datepicker from 'js-datepicker'
import 'select2'
import 'select2/dist/css/select2.css'
import DataTable from 'datatables.net-dt';
import DataTableTyle from 'datatables.net-dt/css/dataTables.dataTables.min.css'
export default class Shared {
  tablePagynateWithJs;

  newPagynateWithJs(tableEle, classRender = '') {
    this.tablePagynateWithJs = $(tableEle).DataTable({
      responsive: false,
      autoWidth: false,
      preDrawCallback: function (settings) {
        if (classRender) $(classRender).css({width: '100%'})
      },
      lengthMenu: [
        [10, 30, 50, 100],
        [10, 30, 50, 100],
      ],
      searching: false,
      ordering:  false,
      processing: true,
      info: false,
      language : {
        sLengthMenu: "_MENU_",
      }
    });
  }

  async reDrawDataTable() {
    await this.tablePagynateWithJs.rows().nodes().page.len(-1).draw(false);
  }

  removeNewPagynateWithJs() {
    if (this.tablePagynateWithJs) this.tablePagynateWithJs.destroy()
  }

  removeImage(element, bindElement, textConfirm = '削除してもよろしいですか？') {
    if (confirm(textConfirm)) {
      $(element).parent().remove();
      $(bindElement).prop('checked', true);
    }
  }

  toggleDisabledClass(element, boolean) {
    element.toggleClass('disabled', boolean)
  }

  toggleAttribute(element, attribute, isBoolean) {
    element.prop(attribute, isBoolean)
  }

  callAjax(url, data = {}, dataType = 'json', method = 'POST', showPreloader = true, sendFile = false) {
    let dataSend = this._fetchDataSendForAjax(url, data, dataType, method, showPreloader, sendFile)
    const dataAjax = $.ajax(dataSend)
    return dataAjax;
  }

  runSelect2(className) {
    $(`select.${className}:not(.select2-hidden-accessible)`).select2();
  }

  loadingIndicator() {
    $('.preloader').prop('style', '').addClass('loading').fadeIn();
  }

  errorFieldMessage(element, message, iRemoveEle = false) {
    element.addClass('is-invalid');
    if (iRemoveEle) $(element).val('')
    element.attr('placeholder', message);
    setTimeout(function() {
        element.removeClass('is-invalid');
    }, 5000);
    if (element.hasClass('select2-cc')) {
      const select2Element = element.parent().find('span.select2-container')
      select2Element.css('border', '1px solid red')
      setTimeout(function() {
        select2Element.css('border', '');
      }, 5000);
    }
  }

  checkValidationUserName(value) {
    let expression_name = /^[a-zA-ZＡ-Ｚａ-ｚ\u4E00-\u9FFFぁ-ゔァ-ヴー\s\　]+$/;
    let regex_name = new RegExp(expression_name);
    let text = value.toLowerCase();
    let isError = text.includes('アカポン') || text.includes('テスト') || text.includes('akapon') || text.includes('test') || !value.match(regex_name)

    return !!isError
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    let amount = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
    return (sizes[i] == 'MB' ? `${Math.round(amount)} ${sizes[i]}` : `${amount} ${sizes[i]}`);
  }

  reloadPage() {
    window.location.reload();
  }

  validateInputDecimalNumber(className, number_round) {
    $('body').on("keypress keyup blur", `.${className}`, (event) => {
      const $element = $(event.target);
      $element.val($element.val().replace(/[^0-9\.]/g,''));
      if ((event.which != 46 || $element.val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
          event.preventDefault();
      }
      const value = $element.val();
      const valueToArray = value.split('.');
      if (valueToArray[1] != undefined && valueToArray[1].length > number_round) {
        $element.val(Number($element.val()).toFixed(number_round))
      }
    })
  }

  validateInputIntegerNumber(className, number_round) {
    $('body').on("keypress keyup blur", `.${className}`, (event) => {
      const $element = $(event.target);
      $element.val($element.val().replace(/[^0-9\.]/g,''));
      if ((event.which < 48 || event.which > 57)) {
          event.preventDefault();
      }
      const value = $element.val();
      const valueToArray = value.split('.');
      if (valueToArray[1] != undefined && valueToArray[1].length > 2) {
        $element.val(Number($element.val()).toFixed(number_round))
      }
    })
  }

  isValidDate(dateString) {
    var dateFormat = /^\d{4}\/\d{2}\/\d{2}$/;
    if (!dateFormat.test(dateString)) {
      return false;
    }

    return (new Date(dateString) != "Invalid Date") && !isNaN(new Date(dateString));
  }

  customDatepicker(className, options) {
    $(`.${className}`).each(function() {
      if(!$(this).data("datepicker")) {
        $(this).data('datepicker', true)
        datepicker(this, options)
      }
    })
  }

  beforeUnloadWithCallBack(functionCallBack) {
    $(window).on('beforeunload', (event) => {
      if (typeof functionCallBack.callback === 'function') functionCallBack.callback(event)
    })
  }

  redirectWithCallBack(functionCallBack) {
    let selector = 'a'
    if (functionCallBack.except_selector) selector = `${selector}:not(${functionCallBack.except_selector})`;
    $(selector).on('click', function (e) {
      if (typeof functionCallBack.onClick === 'function') functionCallBack.onClick(e)
    })
  }

  onlyOneValueForCheckBox(element) {
    $('.check-type-company').prop("checked", false)
    $(element).prop("checked", true)
    $('#js-value-company-type').val($(element).val())
    var disabled = $(element).val() !== 'individual'
    $('#company_company_profile_attributes_career').attr('disabled', false)
    $("#company_company_profile_attributes_work_type_本業").attr('disabled', false)
    $("#company_company_profile_attributes_work_type_副業").attr('disabled', false)
  }

  onlyOneValueForCheckBoxType(element) {
    $('.check-type-company').prop("checked", false)
    $(element).prop("checked", true)
    $('#js-value-company-type').val($(element).val())
  }

  removeElementOfArray(array, element) {
    return array.filter(function(ele){
      return ele !== element;
    });
  }

  showDropdownSubmenu(element, event) {
    $(element).parent('li').siblings().removeClass('show')
    $(element).parent('li').siblings().find('.dropdown-menu').removeClass('show')
    $(element).parent('li').toggleClass('show');
    $(element).next('div').toggleClass('show');
    event.stopPropagation();
    event.preventDefault();
  }

  showElementForMobile() {
    if (window.innerWidth >= 1024) {
      Visiable.show($('.show-for-pc'));
      Visiable.hide($('.show-for-mobile'));
      // $('.show-document-project').removeClass('pl-5');
    } else {
      Visiable.hide($('.show-for-pc'));
      Visiable.show($('.show-for-mobile'));
      // Visiable.show($('.tabmenu'));
      // $('.show-document-project').addClass('pl-5');
    }
  }

  screenMobile() {
    return window.innerWidth < 1024;
  }

  showHideSearch() {
    if (window.innerWidth < 1024) {
      $('.search_form_outer').removeClass('d-none')
    } else {
      if (!$('.custom-control-input').prop("checked")) {
         $('.search_form_outer').addClass('d-none')
      }
    }
  }

  numberZero(number) {
    number = parseFloat(number);
    return number === 0 || isNaN(number)
  }

  runFunctionSetTimeout(functionRun, milliSecond = 5000) {
    if (typeof functionRun !== 'function') return

    setTimeout(functionRun, milliSecond);
  }

  changeCurrentUrl(newUrl) {
    history.pushState(null, 'change url', newUrl);
  }

  checkBoxAsRadius(element) {
    const $ele = $(element);
    const previousCheck = $ele.prop('checked')
    $(`input:checkbox[name="${$ele.attr('name')}"]`).prop('checked', false)
    $ele.prop('checked', previousCheck);
  }

  textWithTextServeResponse(text) {
    if (text.includes('&lt;br&gt;')) {
      const textSplit = text.split('&lt;br&gt;')
      return `${textSplit[0]} <br> ${textSplit[1]}`
    } else {
      return text
    }
  }

  showInputError(input, textError) {
    $(input).addClass('is-invalid').parent().css('border', '2px solid red');
    if ($(input).val().trim() === '') $(input).attr('placeholder', textError)
  }

  removeClassInInputError(input) {
    $(input).removeClass('is-invalid').parent().removeAttr('style');
  }

  numberToCurrency(amount) {
    amount += '';
    var x = amount.split('.');
    var x1 = x[0];
    var x2 = parseInt(x[1]) > 0 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  };

  clickCheckAll(element) {
    if (element.checked == true){
      $('input.select-supplier').prop('checked', true);
    } else {
      $('input.select-supplier').prop('checked', false);
    }
  }

  reloadCurrentPage() {
    window.location.reload();
  }

  _fetchDataSendForAjax(url, data = {}, dataType = 'json', method = 'POST', showPreloader = true, sendFile = false) {
    let self = this
    let dataSend = {
      beforeSend: function () {
        if (showPreloader) self.loadingIndicator();
      },
      method: method,
      url: url,
      dataType: dataType,
      data: data,
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      complete: function () {
        $('.preloader').fadeOut();
      }
    }
    if (sendFile) {
      dataSend['processData'] = false
      dataSend['contentType'] = false
    }
    return dataSend
  }

  showModalForElement(element){
    $(element).modal('show');
  }

  hideModalForElement(element){
    $(element).modal('hide');
  }

  showModalForElementNotClose(element) {
    $(element).modal({ show: true, backdrop: 'static', keyboard: false });
  }

  delay(callback, ms) {
    var timer = 0;
    return function() {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  roundNumber(num, scale) {
    if(!("" + num).includes("e")) return +(Math.round(num + "e+" + scale)  + "e-" + scale);

    var arr = ("" + num).split("e");
    var sig = "";
    if(+arr[1] + scale > 0) sig = "+";
    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }

  removeDatepicker(ele, parentEle) {
    const newEle = $(ele).clone()
    $(ele).remove()
    parentEle.append(newEle)
  }

  isVideoType(type) {
    return ['動画', 'video'].includes(type);
  }

  closeModalForElement(element){
    $(element).modal('hide');
  }

  fetchPagynateWithJs(total, numberOfPage) {
    if (total > numberOfPage) {
      let pagynate;
      if (parseInt(total / numberOfPage) > 1) {
        pagynate = (total % numberOfPage) === 0 ? (total / numberOfPage) : parseInt(total / numberOfPage) + 1;
      } else {
        pagynate = 2;
      }
      return pagynate;
    } else {
      return 1;
    }
  }

  async reRenderPagyNate(pagynate, onclick_back, select_page, next_page) {
    const pageText = await this.renderPageWithJs(pagynate, select_page)
    return `
      <div class="render_pagy_with_js">
        <nav class="pagy-bootstrap-nav" role="navigation" aria-label="pager">
          <ul class="pagination">
            <li class="page-item prev cursor-pointer" onclick="${onclick_back}">
              <a class="page-link">‹&nbsp;Prev</a>
            </li>
            ${pageText}
            <li class="page-item next cursor-pointer" onclick="${next_page}">
              <a class="page-link" rel="next" aria-label="next">Next&nbsp;›</a>
            </li>
          </ul>
        </nav>
      </div>
    `;
  }

  renderPageWithJs(pagynate, select_page) {
    let text = ''

    if (pagynate <= 6) {
      for (let index = 1; index <= pagynate; index ++) {
        text += `
          <li class="page-item cursor-pointer ${index == 1 ? 'active' : '' }" data-index="${index}" onclick="${select_page}">
            <a class="page-link">${index}</a>
          </li>
        `
      }
    } else {
      for (let index = 1; index <= 3; index ++) {
        text += `
          <li class="page-item cursor-pointer ${index == 1 ? 'active' : '' }" data-index="${index}" onclick="${select_page}">
            <a class="page-link">${index}</a>
          </li>
        `
      }
      for (let index = 4; index <= (pagynate - 3); index ++) {
        text += `
          <li class="d-none page-item cursor-pointer ${index == 1 ? 'active' : '' }" data-index="${index}" onclick="${select_page}">
            <a class="page-link">${index}</a>
          </li>
        `
      }
      text += `<li class="page-link">...</li>`
      for (let index = (pagynate - 2); index <= pagynate; index ++) {
        text += `
          <li class="page-item cursor-pointer ${index == 1 ? 'active' : '' }" data-index="${index}" onclick="${select_page}">
            <a class="page-link">${index}</a>
          </li>
        `
      }
    }

    return text
  }

  appendSelect2(id_parent, id_element) {
    $(id_element).select2({
      width: "100%",
      dropdownParent: $(id_parent),
      "language": {
        "noResults": function(){
          return "検索に該当する結果はありません";
        }
      }
    });

    $(id_element).on('select2:open', function (e) {
      $(".select2-dropdown .select2-results .select2-results__options").css("cssText", "max-height: 105px !important");
      $(".select2-dropdown").click(function(event){
        event.stopPropagation();
      });
    });
  }

  appendSelect2Task(id_parent, id_element) {
    const shared = this;
    $(id_element).select2({
      width: "100%",
      dropdownParent: $(id_parent),
      templateResult: shared._formatState,
      "language": {
        "noResults": function(){
          return "検索に該当する結果はありません";
        }
      }
    });

    $(id_element).on('select2:open', function (e) {
      $(".select2-dropdown").click(function(event){
        event.stopPropagation();
      });
    });
  }

  _formatState (state) {
    if (!state.id) { return state.text; }

    let svg = gon.svg.web;
    if ($(state.element).attr('data-category') == 'video') {
      svg = gon.svg.video;
    }
    var $state = $(
     '<div class="d-flex justify-content-space-between"><span>' + state.text + '</span><img src=' + svg + ' class="icon mr-1" width="30" height="20"></img></div>'
    );
    return $state;
  }

  removeParamsWithUrlPagyOrTagA(paramsRemove, arrayClassTagA = []) {
    $('ul.pagination').find('a.page-link').toArray().forEach(tagA => {
      this.removeParamsForTagA(paramsRemove, tagA)
    })
    if (arrayClassTagA.length === 0) return

    arrayClassTagA.forEach(classTagA => {
      $(`a.${classTagA}`).toArray().forEach(tagA => {
        this.removeParamsForTagA(paramsRemove, tagA)
      })
    })
  }

  removeParamsForTagA(paramsRemove, tagA) {
    if (!$(tagA).attr('href') || $(tagA).attr('href') === '#') return

    let paramsObject = new URLSearchParams($(tagA).attr('href').split('?')[1]);
    paramsObject.delete(`${paramsRemove}`);
    $(tagA).attr('href', `${$(tagA).attr('href').split('?')[0]}?${paramsObject.toString()}`)
  }

  changeSortTicketMobile(ele, type) {
    const searchPopupEle = this._fetchSearchPopupEle(ele);
    const keySort = $(ele).val();
    if(keySort == 'customize_sort') {
      $('.option-default-sort').addClass('d-none');
      $('.option-default-sort_updated_at').addClass('d-none');
      $('.option-customize-sort').removeClass('d-none');
      $('.option-customize-sort').find('option.can-select').first().prop('selected', 'selected').change();
      $(`#sort-${type}-items`).attr('disabled', true);
      $('#sp-object-sort-id').attr('disabled', false);
    } else {
      searchPopupEle.find(`#sort-${type}-items`).attr('name', `sort_by[${keySort}]`)
      $(`#sort-${type}-items`).attr('disabled', false);
      $('#sp-object-sort-id').attr('disabled', true);

      if(keySort === 'updated_at') {
        $('.option-default-sort').addClass('d-none');
        $('.option-customize-sort').addClass('d-none');
        $('.option-default-sort_updated_at').removeClass('d-none');
        $('.option-default-sort_updated_at').find('option.can-select').first().prop('selected', 'selected').change();
      } else {
        $('.option-default-sort').removeClass('d-none');
        $('.option-customize-sort').addClass('d-none');
        $('.option-default-sort_updated_at').addClass('d-none');
        $('.option-default-sort').find('option.can-select').first().prop('selected', 'selected').change();
      }
    }
  }

  changeValueSortTicketMobile(ele, type) {
    const searchPopupEle = this._fetchSearchPopupEle(ele)

    if($(".select-sort-type").val() == 'customize_sort') {
      if($(ele).val() === 'create') {
        this.showModalForElement('#createCustomizeSort')
        SearchForm.filterDisplay('spclose')
      } else {
        searchPopupEle.find('#sp-object-sort-id').val($(ele).val())
      }
    } else {
      searchPopupEle.find(`#sort-${type}-items`).val($(ele).val())
    }
  }

  _fetchSearchPopupEle(ele) {
    return $(ele).parents('div.pop_search')
  }

  initCustomizeSortSp(sort_customize_check, sort_by_updated) {
    if(sort_customize_check === 'true') {
      $('.select-sort-type').val('customize_sort')
      $('#sort-ticket-items').attr('disabled', true)
      $('#sp-object-sort-id').attr('disabled', false)
      $('.option-default-sort').addClass('d-none');
      $('.option-customize-sort').removeClass('d-none');
      $('.option-default-sort_updated_at').addClass('d-none');
    } else {
      if(sort_by_updated === 'true') {
        $('.option-default-sort').addClass('d-none');
        $('.option-default-sort_updated_at').removeClass('d-none');
      } else {
        $('.option-default-sort').removeClass('d-none');
        $('.option-default-sort_updated_at').addClass('d-none');
      }
      $('.option-customize-sort').addClass('d-none');
      $('#sort-ticket-items').attr('disabled', false)
      $('#sp-object-sort-id').attr('disabled', true)
    }
  }

  textTaskAkaire(text_task, text_akaire) {
    if(gon.is_plan_akapon) {
      return text_akaire
    } else {
      return text_task
    }
  }

  checkDeviceWithTouchAction() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  selectOptionModalNewSearch(elementSearch, value, element) {
    if (elementSearch.includes('_in')) {
      const statusValue = $(element).parent().find(`${elementSearch}`).val();
      if (statusValue.includes(value)) return
      $(element).parent().find(`${elementSearch}`).val(`${statusValue} ${value}`);
    } else {
      $(element).parent().find('.option').removeClass('slted')
      $(element).parent().find(`${elementSearch}`).val(value);
    }
    $(element).addClass('slted')
    SearchForm.countNumberForSearchNew(element)

    if(elementSearch === '#filter_type') {
      const type_text = {effort_of_owner: '自社数', effort_of_all_supplier: '仕入数', effort_owner_and_supplier_submited: '販売数'}
      $('.header-popup-effort-by-role').text(type_text[value])
    }
  }

  checkShowPopupForNewSearch(ele) {
    const popup = $(ele)[0];
    if (!popup || $(popup).hasClass('check-right')) return

    const windowWidth = window.innerWidth;
    let popupRight = popup.getBoundingClientRect().right;
    popupRight = $(popup).hasClass('drop-right') ? popupRight + $(ele).width() : popupRight

    if (popupRight >= windowWidth) {
        popup.classList.add('check-right');
    } else {
        popup.classList.remove('check-right');
    }
  }

  enableSubmitButton(button_id) {
    $(`#${button_id}`).removeClass('disabled')
  }

  lpSite() {
    if(gon.is_plan_akapon) {
      return 'https://kanritools.com/'
    } else {
      return 'https://createcloud.jp/'
    }
  }

  copyText(element, message) {
    $(element).focus();
    $(element).select();
    if (window.isSecureContext && navigator.clipboard) {
      window.navigator.clipboard.writeText($(element).val());
    } else {
      document.execCommand('copy');
    }
    if (message) Popup.alert(message);
  }

  formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.round(seconds % 60);

    const formattedTime = [hours, minutes, remainingSeconds]
      .map(unit => String(unit).padStart(2, '0'))
      .join(':');

    return formattedTime;
  }

  openModalAndRemoveBackDrop(id_element) {
    $(id_element).modal('show');
    setTimeout(() => {
      $('.modal-backdrop').remove()
    }, 500);
  }
}
