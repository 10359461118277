import React, { Component } from "react";
import {
  canUpdateAnimationMarker,
  canEditComment,
  canUseReplyFeature,
  canDeleteCommentUserLogin,
  canDeleteAnimationMarker,
  canDeleteAnimationMarkerPublicUser,
  canUserNotPermission,
  canCreateCommentPublicUser
} from "../authorizations";
import moment from "moment";
import { Button, CardLink } from "reactstrap";
import iconPenEdit from "../ui/icons/iconPenEdit";
import iconBin from "../ui/icons/iconBin.svg";
import iconReply from "../ui/icons/iconReply.png";
import iconPaperclip from "../ui/icons/iconPaperclip";
import TextEditor from "./TextEditor";
import objectToFormdata from "object-to-formdata";
import { renderByFileUrl } from "../util/buildUrl";
import UserToSelect from "./UserToSelect";
import apiClient from "../util/api-client";
import TextEditorWidgetComponent from "./TextEditorWidgetComponent";
import { isValidAttachFiles } from '../util/checkValidate';
import iconDeleteFile from "../../../assets/images/icon_x_circle.svg";
import userIcon from '../../../assets/images/user_icon_2.png'
import ModalShowPopupNotifyReplyToNotLogin from './ModalShowPopupNotifyReplyToNotLogin'

export default class CommentDetail extends Component {
  constructor(props) {
    super(props);

    const { selectedMarker } = this.props;

    this.state = {
      body: "",
      replyBody: "",
      isSubmitting: false,
      isReplying: false,
      isOpenModalTo: false,
      disabledButton: true,
      files: [],
      uploadFiles: [],
      editorSetting: {
        borderColor: "#E60C11",
      },
      editor: null,
      isEditing: false,
      status: null,
      selectedMembers: [],
      editor: null,
      editorSetting: {
        borderColor: '#E60C11'
      },
      listFileDelete: [],
      listAllFiles: [],
      isShowModalNotifyOfUserNotLogin: false,
      isShowModalNotLogin: !gon.is_not_show_modal_reply_akaire_to_not_login
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.selectedMarker?.id !== this.props?.selectedMarker?.id) {
      this.setState({
        body: this.props?.selectedMarker?.body || "",
        isSubmitting: false,
        isReplying: false,
        isOpenModalTo: false,
        disabledButton: true,
        uploadFiles: [],
        listAllFiles: this.fetchListAllFile() || [],
        listFileDelete: [],
        files: this.props?.selectedMarker?.files || [],
        editorSetting: {
          borderColor: "#E60C11",
        },
        editor: null,
        isEditing: false,
        status: this.props?.selectedMarker?.status,
        selectedMembers: this.props.selectedMarker?.usersToInReplyComent,
        editor: null,
        editorSetting: {
          borderColor: '#E60C11'
        },
        isShowModalNotifyOfUserNotLogin: false,
      });
    }
  }

  fetchListAllFile() {
    const { isReplying } = this.state
    if(isReplying) return []
    const listFile = []
    if (this.props?.selectedMarker?.files) {
      $.each(this.props?.selectedMarker?.files, function(k, file) {
        listFile.push({id: file.id, name: decodeURI(file.file_object.url.split('/').slice(-1)[0]) })
      });
    }
    return listFile;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  toggleModalReply = () => {
    this.setState({isShowModalNotifyOfUserNotLogin: !this.state.isShowModalNotifyOfUserNotLogin})
  }

  handleClickOutside(event) {
    if ($(event.target).hasClass('modal-style modal-not-reply-to-not-login') ||
        $('.modal-not-reply-to-not-login').length > 0 && $('.modal-not-reply-to-not-login')[0].contains(event.target)) {
      return
    }
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.updateSelectedMarker(null);
    }
  }

  onChangeBody = (value) => {
    if(this.state.isReplying) {
      this.setState({ replyBody: value });
    } else if(this.state.isEditing) {
      this.setState({ body: value });
    }
    this.setState({ disabledButton: !value });
  };
  onAddFiles = (files) => {
    if (!isValidAttachFiles(this.state.uploadFiles, files)) return;
    this.setState({ uploadFiles: [...this.state.uploadFiles, ...files] });
  };
  onFileChange = (event, files) => {
    const listFileName = []
    const { listAllFiles } = this.state;
    $.each(listAllFiles, function(k, file) {
      listFileName.push(file)
    });
    if (event) {
      const { files } = event.target;

      if (!isValidAttachFiles(this.state.uploadFiles, files)) {
        event.target.value = null;
        return;
      }
      $.each(files, function(k, file) {
        const id = new Date().getTime() + k
        file.id_temp = id
        listFileName.push({ id: id, name: file.name})
      })
      this.setState({ uploadFiles: [...this.state.uploadFiles, ...files] });
      this.setState({ disabledButton: false, listAllFiles: listFileName })
      event.target.value = null;
    } else {
      if (files) {
        $.each(files, function(k, file) {
          const id = new Date().getTime() + k
          listFileName.push({ id: id, name: file.name})
        })
        this.setState({ uploadFiles: [...this.state.uploadFiles, ...files] });
        this.setState({ disabledButton: false, listAllFiles: listFileName })
      }
    }
  };

  deleteUploadFile = (fileId) => {
    const newUploadFiles = [];
    const newListAllFiles = [];
    const newListDeleteFiles = [];
    const { uploadFiles, listAllFiles, listFileDelete } = this.state;
    $.each(listFileDelete, function(k, id) {
      newListDeleteFiles.push(id)
    })
    $.each(uploadFiles, function(k, file) {
      if (file.id_temp !== fileId) {
        newUploadFiles.push(file)
      }
    })
    $.each(listAllFiles, function(k, file) {
      if (file.id !== fileId) {
        newListAllFiles.push(file)
      } else {
        newListDeleteFiles.push(file.id)
      }
    })
    this.setState(
      { uploadFiles: newUploadFiles, listAllFiles: newListAllFiles,
        listFileDelete: newListDeleteFiles, disabledButton: false }
    );
  }

  /**
   * Check show delete button
   */
  showDeleteMarker(user) {
    const { currentUserId, role, owner } = this.props;
    if (!user || (currentUserId != user.id)) return false;
    // current user have role owner, reader, view, edit
    if (currentUserId && canDeleteCommentUserLogin(role)) {
      return canDeleteAnimationMarker(user && user.id, currentUserId, role);
    }
    // current user have role public_user
    if (
      (currentUserId && canUserNotPermission(role) && !!owner) ||
      (!currentUserId && canUserNotPermission(role) && !!owner)
    ) {
      return false;
    }
    // public user
    return canDeleteAnimationMarkerPublicUser(user && user.id, currentUserId);
  }

  updateIsEditing(boolean) {
    this.setState({ isEditing: boolean });
    this.setState({ isReplying: false, files: [] })
  }

  changeIsShowModalNotLogin = (bolean) => {
    this.setState({ isShowModalNotLogin: bolean });
  }

  updateIsReplying(boolean) {
    const {isShowModalNotLogin} = this.state;
    const { selectedMarker } = this.props;
    const isShowModal = !selectedMarker.user && selectedMarker.user_name_temp && boolean && isShowModalNotLogin
    this.setState({ isReplying: boolean, files: [], listAllFiles: [], isShowModalNotifyOfUserNotLogin: isShowModal });
  }

  updateIsOpenModalTo = (boolean) => {
    this.setState({ isOpenModalTo: boolean });
  };

  updateSelectedMember = (selectedMembers) => {
    this.setState({ selectedMembers: selectedMembers });
    this.updateIsOpenModalTo(false);
  };

  setEditor = (value) => {
    this.setState({ editor: value });
  }

  /**
   * Action update comment status
   */
  onClickUpdate = (values, targetId) => {
    const { onMarkerUpdationCalled } = this.props;

    onMarkerUpdationCalled(values, targetId);
    this.setState({ status: values.status });

    let scrollTopPosition = this.fetchPositionComment(targetId);
    if ($('.marker-' + targetId).length > 0) {
      $('.wrap-animation-preview').animate({
        scrollTop: $('.marker-' + targetId).position().top - 200
      }, 'slow');
    }
    this.keepPositionComment(targetId, scrollTopPosition)
  };

  onSubmitReply = () => {
    const { replyBody, selectedMembers, uploadFiles } = this.state;
    const { currentUserId, reloadMarkers, selectedMarker } =
      this.props;
    const { id, parent_id, isComment } = selectedMarker;

    this.setState({ isSubmitting: true });
    const data = objectToFormdata({
      reply_comment: {
        body: replyBody,
        reply_to: selectedMembers ? selectedMembers.map((option) => option.value) : [],
        user_id: currentUserId,
        attachment_files_attributes: uploadFiles.map((file) => {
          return {
            file_object: file,
          };
        }),
      },
    });
    return apiClient()
      .post(
        `/akaire_feature/animation_markers/${isComment ? id : parent_id}/reply_comments`,
        data
      )
      .then(({ data: { id } }) => {
        reloadMarkers().then((_) => {
          // scrollToReply(id);
        });
      })
      .catch((e) => {
        toast.error("失敗しました。");
        reloadMarkers();
      })
      .finally((_) => this.onSubmitSuccess("reply"));
  };

  onSubmitUpdateMarker = () => {
    const { apiClient, reloadMarkers } = this.props;
    const { body, uploadFiles, listFileDelete } = this.state;

    const data = objectToFormdata({
      animation_marker: {
        body: body,
        animation_marker_files_attributes: uploadFiles.map((file) => {
          return {
            file_object: file,
          };
        }),
      },
      list_delete_files: listFileDelete
    });

    this.setState({ isSubmitting: true, isEditing: false });

    return apiClient
      .patch(
        `animations/${this.props.selectedMarker.animation_id}/animation_markers/${this.props.selectedMarker.id}`,
        data
      )
      .then((_) => {
        this.setState({ isSubmitting: false });
        reloadMarkers();
      })
      .catch((_) => {
        this.setState({ isSubmitting: false });
        toast.error("コメントの投稿に失敗しました。");
      })
      .finally((_) => this.onSubmitSuccess("edit"));
  };

  onSubmitUpdateReply = () => {
    const { reloadMarkers, selectedMarker } = this.props;
    const { id, parent_id } = selectedMarker;
    const { replyBody, body, uploadFiles, listFileDelete } = this.state;
    const data = objectToFormdata({
      reply_comment: {
        body: body,
        attachment_files_attributes: uploadFiles.map((file) => {
          return {
            file_object: file,
          };
        })
      },
      list_delete_files: listFileDelete
    });
    // keepPositionComment(parentReplyId, position)
    return apiClient()
      .patch(
        `/akaire_feature/animation_markers/${parent_id}/reply_comments/${id}`,
        data
      )
      .then((_) => {
        reloadMarkers();
        setTimeout(function () {
          // if (position > 0) {
          //   keepPositionComment(parentReplyId, position)
          // }
        }, 300);
      })
      .catch((e) => console.error(e.message))
      .finally((_) => this.onSubmitSuccess("edit"));
  };

  onClickDelete(targetId) {
    const { selectedMarker, reloadMarkers } = this.props
    const { animationSelectCurrent, currentAnimationId, updateSelectedMarker, deleteReplyMarker } =
      this.props;
    if (animationSelectCurrent !== currentAnimationId) return;

    return (event) => {
      event.stopPropagation();
      event.preventDefault();
      Popup.confirm(
        'このコメントを削除します。<br>よろしいですか？',
        {
          confirmText: 'OK',
          cancelBtn: 'false',
          success: () => {
            if(selectedMarker.isComment) {
              const { onMarkerDeletionCalled } = this.props;
              onMarkerDeletionCalled(targetId);
            } else {
              deleteReplyMarker(targetId, selectedMarker.parent_id)
              setTimeout(() => {reloadMarkers();}, 200)
            }
            updateSelectedMarker(null);
            this.onSubmitSuccess("delete");
            Popup.alert('コメントを削除しました。')
          }
        }
      )
    };
  }

  onSubmitSuccess = (type) => {
    switch (type) {
      case "reply":
        Popup.alert('', '', 'コメントに返信しました。', {kind: 'akaire'});
        break;
      case "edit":
        Popup.alert('', '', 'コメントを更新しました。', {kind: 'akaire'});
        break;
      case "delete":
        Popup.alert('', '', 'コメントを削除しました。', {kind: 'akaire'});
        break;
    }
  };

  // Setting BUA
  handleSetEditorSetting = (settingData) => {
    this.setState({
      editorSetting: settingData
    })
  };

  onClickAttachmentFile = () => {
    this.refs.fileInput.click();
  }

  fetchPositionComment = (id) => {
    let scrollTopPosition = 0;
    if ($(`.animation-marker-${id}`).length > 0) {
      const divScroll = $(`.animation-marker-${id}`).parents('.items')[0];
      scrollTopPosition = divScroll.scrollTop;
    }
    return scrollTopPosition
  }

  keepPositionComment = (id, scrollTopPosition) => {
    if (scrollTopPosition !== 0) {
      $(`.animation-marker-${id}`).parents('.items').animate({
        scrollTop: scrollTopPosition
      }, 'slow');
    }
  }

  renderCommentBody = (body, files) => {
    return (
      <div className="comment-detail-info-body">
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
        <div className="attached-files">
          {files.map((file, i) => {
            return (
              <div className="attached-file my-2" key={i}>
                <a
                  href={file.file_object.url}
                  target="_blank"
                  className="cursor-pointer"
                >
                  {renderByFileUrl(file.file_object.url)}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    )
  }

  renderCommentChildren = (body, files) => {
    return (
      <div className="comment-detail-info-body comment-children">
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
        <div className="attached-files">
          {files.map((file, i) => {
            return (
              <div className="attached-file my-2" key={i}>
                <a
                  href={file.file_object.url}
                  target="_blank"
                  className="cursor-pointer"
                >
                  {renderByFileUrl(file.file_object.url)}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    )
  }

  renderComentDetail(selectedMarker, shouldShowForm) {
    const { currentUserId, role, mobile: isMobile, avatarUrl } = this.props;
    const {
      status,
      body,
      replyBody,
      isSubmitting,
      files,
      isEditing,
      isReplying,
      isOpenModalTo,
      selectedMembers,
      editor,
      uploadFiles,
      disabledButton,
      listAllFiles,
      isShowModalNotifyOfUserNotLogin
    } = this.state;
    const showAvatarUrl = selectedMarker.user?.chat_avatar_url;

    if (shouldShowForm) return (<></>)

    return (
      <div className="comment-detail-group">
        {isOpenModalTo && (
          <UserToSelect
            isOpenModalTo={isOpenModalTo}
            receivers={selectedMarker.receivers}
            updateSelectedMember={this.updateSelectedMember}
            onClose={this.updateIsOpenModalTo}
            defaultUserTo={[]}
          ></UserToSelect>
        )}

        <div className="comment-detail-info d-flex justify-content-between">
          <div className="comment-detail-user-info d-flex">
            {showAvatarUrl ? (
              <img className="comment-detail-info-avatar" src={showAvatarUrl}></img>
            ) : (
              selectedMarker.user?.name[0] ? (
                <span className="comment-detail-info-avatar">
                {selectedMarker.user?.name[0]}
              </span>
              ) : (<img className="comment-detail-info-avatar for-not-login" style={{backgroundColor: 'none'}} src={userIcon}></img>)
            )}

            {isEditing || isReplying ? (
              <div className="w-100">
                {
                  isReplying && (
                    <div className="show-reply-source-text-wrapper">
                      <div>返信元の文章を表示</div>
                      <div className="show-reply-source-text-tooltip p-2 mt-1">
                        <div className="comment-detail-info d-flex justify-content-between">
                          <div className="comment-detail-user-info d-flex">
                            {1 === 2 ? (
                              <img className="comment-detail-info-avatar" src={ showAvatarUrl ? showAvatarUrl : '' }></img>
                            ) : (
                              <span className="comment-detail-info-avatar">
                                {selectedMarker.user?.name[0]}
                              </span>
                            )}
                            { this.renderCommentBody(body, files) }
                          </div>
                        </div>

                        <div className="comment-detail-action">
                          <div className="comment-detail-action-info d-flex">
                            <div className="comment-detail-user-name text-ellipsis max-width-195px">
                              {selectedMarker.user?.name}
                            </div>
                            <div className="comment-detail-created-at">
                              {moment(selectedMarker.created_at).format(
                                "YYYY/MM/DD HH:mm:ss",
                                "Asia/Tokyo"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                <TextEditor
                  files={files}
                  onChangeBody={this.onChangeBody}
                  onAddFiles={this.onAddFiles}
                  disabled={isSubmitting}
                  setEditor={this.setEditor}
                  rows={4}
                  placeholder={"ここにコメントを入力"}
                  initialValue={isReplying ? "" : this.props.selectedMarker.body}
                  inputId="animation-marker-body-comment"
                  is_comment={true}
                />
              </div>
            ) : (
              selectedMarker.isComment ? this.renderCommentBody(body, files) : this.renderCommentBody(selectedMarker.parentsComment, [])
            )}
          </div>
          {selectedMarker.isComment ? (
            <div className="comment-detail-record-info d-flex">
              {
                (isEditing || isReplying) && <TextEditorWidgetComponent
                  mobile={isMobile}
                  settingEditor={this.handleSetEditorSetting}
                  closeEditor={() => {}}
                  fileUpload={()=>{}}
                  editor={editor}
                  onlyBUA={true}
                />
              }
              {selectedMarker.time != null && selectedMarker.isVideo && (
                selectedMarker.periodEnd ? (
                  <div className="comment-detail-time">
                    {moment.utc(selectedMarker.time * 1000).format("HH:mm:ss.S")} ~ {moment.utc(selectedMarker.periodEnd * 1000).format("HH:mm:ss.S")}
                  </div>
                ) : (
                  <div className="comment-detail-time">
                    {moment.utc(selectedMarker.time * 1000).format("HH:mm:ss.S")}
                  </div>
                )
              )}
              <div className="comment-detail-record-circle d-flex align-items-center">
                <div className={`status-field ${!canCreateCommentPublicUser(role) && ('disabled') }`}>
                  {canUpdateAnimationMarker(role) &&
                    (status === "initial" ? (
                      <div data-tip data-for="marker-tooltip">
                        <CardLink
                          onClick={() => {
                            this.onClickUpdate(
                              { status: "completed" },
                              selectedMarker.id
                            );
                          }}
                          href="#"
                        >
                          <span className="far fa-circle status-circle"></span>
                        </CardLink>
                      </div>
                    ) : (
                      <div data-tip data-for="marker-tooltip">
                        <CardLink
                          onClick={() => {
                            this.onClickUpdate(
                              { status: "initial" },
                              selectedMarker.id
                            );
                          }}
                          href="#"
                        >
                          <span className="far fa-check-circle status-circle status--completed"></span>
                        </CardLink>
                      </div>
                    ))}
                </div>
                <div
                  className="border-none numerical-order-container d-flex justify-content-center align-items-center"
                  style={{ backgroundColor: selectedMarker.border_color }}
                >
                  <div className="comment-no">{selectedMarker.number_comment}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="comment-detail-record-info d-flex">
              {
                (isEditing || isReplying) && <TextEditorWidgetComponent
                  mobile={isMobile}
                  settingEditor={this.handleSetEditorSetting}
                  closeEditor={() => {}}
                  fileUpload={()=>{}}
                  editor={editor}
                  onlyBUA={true}
                />
              }
            </div>
          )}
        </div>

        {selectedMembers ? (
          <div>
            <div className="comment-detail-to-list d-flex">
              <div className="icon-to">TO</div>
              <div className="list-user">
                {selectedMembers.map((member, i) => (
                  <span key={i}>{member.label}{selectedMembers.length == i + 1 ? '' : ','}&nbsp;</span>
                ))}
              </div>
            </div>
            { this.renderCommentChildren(body, files) }
          </div>
        ) : (
          <div>
          </div>
        )}

        <div className="comment-detail-action d-flex">
          <div className="d-flex comment-action">
            {
              canEditComment(
                currentUserId,
                selectedMarker.user && selectedMarker.user.id
              ) && (
                <CardLink
                  onClick={() => this.updateIsEditing(true)}
                  href="javascript:void(0)"
                >
                  <div className="circle circle-edit-icon">
                    <img className="edit-icon" src={iconPenEdit} />
                  </div>
                  編集
                </CardLink>
              )
            }

            { canCreateCommentPublicUser(role) && (
              (!isReplying && canUseReplyFeature(role)) && (
                <CardLink
                  onClick={() => {
                    this.updateIsReplying(true);
                  }}
                  href="javascript:void(0)"
                >
                  <img className="circle" src={iconReply}/>
                  返信
                </CardLink>
              ))
            }
            {this.showDeleteMarker(selectedMarker.user) && (
              <CardLink
                onClick={this.onClickDelete(selectedMarker.id)}
                href="javascript:void(0)"
              >
                <div className="circle circle-bin-icon">
                  <img className="bin-icon" src={iconBin} />
                </div>
                削除
              </CardLink>
            )}
            {isReplying && (
              <CardLink
                onClick={() => {
                  this.updateIsOpenModalTo(true);
                }}
                href="javascript:void(0)"
              >
                <div className="circle circle-to-icon">TO</div>
                投稿メンバー
              </CardLink>
            )}
          </div>

          <div className="comment-detail-action-info d-flex">
            <div className="comment-detail-user-name text-ellipsis max-width-195px">
              {selectedMarker.user?.name}
            </div>
            <div className="comment-detail-created-at">
              {moment(selectedMarker.created_at).format(
                "YYYY/MM/DD HH:mm:ss",
                "Asia/Tokyo"
              )}
            </div>
            <Button
              className="button-send"
              disabled={(!isEditing && !isReplying) || disabledButton}
              onClick={() => {
                {
                  isReplying
                    ? this.onSubmitReply()
                    : selectedMarker.isComment
                    ? this.onSubmitUpdateMarker()
                    : this.onSubmitUpdateReply();
                }
              }}
            >
              更新
            </Button>
          </div>
        </div>

        {
          (isReplying || isEditing) && (
            <div className="hr"></div>
          )
        }

        {
          (isReplying || isEditing) && (
            <div className="show-detail-upload-file-akaire-page">
              <div className="comment-detail-upload-file d-flex align-items-center">
                <div className="paperclip-box circle" onClick={this.onClickAttachmentFile}>
                  <img src={iconPaperclip} alt="アップロード" />
                </div>
                <input
                  type="file"
                  hidden
                  onChange={this.onFileChange.bind(this)}
                  ref="fileInput"
                  multiple="multiple"
                  accept="image/*, .wav"
                />
              </div>
              {
                listAllFiles.map((file, i) => {
                  return (
                    <div className="d-flex list-file-name justify-content-between align-center">
                      <span key={i} className="text-ellipsis max-width-80percent h-100">{file.name}</span>
                      <span className="attatchment-file-coment-akaire cursor-pointer" onClick={() => this.deleteUploadFile(file.id)}>
                        <img src={iconDeleteFile} alt="アップロード" />
                      </span>
                    </div>
                  )
                })
              }
            </div>
          )
        }
        <ModalShowPopupNotifyReplyToNotLogin isOpen={isShowModalNotifyOfUserNotLogin} togglePopup={this.toggleModalReply}
        changeIsShowModalNotLogin={this.changeIsShowModalNotLogin}/>
      </div>
    );
  }

  render() {
    const { selectedMarker, shouldShowForm } = this.props;

    if (!selectedMarker) {
      return <div className="comment-detail-container"></div>;
    }

    return (
      <div className="comment-detail-container" ref={this.setWrapperRef}>
        {this.renderComentDetail(selectedMarker, shouldShowForm)}
      </div>
    );
  }
}
